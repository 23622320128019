import { Action } from '@lib/plugin-redux-core';
import {
  AssignmentConfigurations,
  AssignmentSummary,
  CreateAssignmentResponse,
} from '@module/assignment';
import {
  Answer,
  CreateFillInBlankAnswerUseCaseInput,
  CreateSectionUseCaseInput,
  CreateSingleMultipleChoiceAnswerUseCaseInput,
  DeleteFillInBlankAnswerUseCaseInput,
  FillInBlankCorrectAnswer,
  FillInBlankQuestion,
  Grade,
  MultipleChoiceQuestion,
  Question,
  QuestionProps,
  QuestionSummary,
  QuestionType,
  SectionSummary,
  SingleChoiceQuestion,
  Subject,
  UpdateFillInBlankAnswerUseCaseInput,
  UpdateQuestionUseCaseInput,
  UpdateSingleMultipleChoiceAnswerUseCaseInput,
  UpdateSingleMultipleChoiceCorrectAnswerUseCaseInput,
  Section,
  Form,
  UpdateFillInBlankCorrectAnswerUseCaseInput,
  UpdateSectionUseCaseInput,
} from '@module/form';
import { REQUEST_STATUS } from '@utils';
import { QuizEditPageState, QuizEditStatus } from './quiz-edit.reducer';

const mapQuestionToQuestionSummaries = (question: Question<QuestionProps>): QuestionSummary => {
  return QuestionSummary.create({
    id: question.id.toString(),
    sectionId: question.sectionId,
    score: question.score,
    description: question.description,
    formId: question.formId,
  }).getValue();
};

const mapQuestionsToQuestionSummaries = (
  questions: Question<QuestionProps>[],
): QuestionSummary[] => {
  return questions.map((q) => {
    return mapQuestionToQuestionSummaries(q);
  });
};

const createSection = (
  createSectionInput: CreateSectionUseCaseInput,
  sections: SectionSummary[],
): SectionSummary[] => {

  const newSection = SectionSummary.create({
    id: createSectionInput.payload.id.toString(),
    title: createSectionInput.payload.title,
    totalQuestion: 0,
    totalScore: 0,
    index: sections.length,
    description: createSectionInput.payload.description,
  }).getValue();
  return [...sections, newSection];
};

const updateSection = (
  useCaseInput: UpdateSectionUseCaseInput,
  sections: SectionSummary[],
): SectionSummary[] => {
  const { sectionId, input } = useCaseInput;
  const { title, description } = input;

  const sectionUpdate = sections.find(
    (section) => section.id.toString() === sectionId,
  ) as SectionSummary;

  const isTitleHaveUpdateValue = title || title === "";
  if (isTitleHaveUpdateValue) {
    sectionUpdate.updateTitle(title);
  }

  const isDescriptionHaveUpdateValue = description || description === '';
  if (isDescriptionHaveUpdateValue) {
    sectionUpdate.updateDescription(description);
  }

  return sections;
};

const deleteSection = (sectionId: string, sections: SectionSummary[]): SectionSummary[] => {
  const sectionsUpdate = sections.filter(
    (section) => section.id.toString() !== sectionId,
  ) as SectionSummary[];
  return sectionsUpdate;
};

const updateQuestionInSummaries = (
  questionSummaries: QuestionSummary[],
  question: Question<QuestionProps>,
): QuestionSummary[] => {
  const questionSummariesUpdate = questionSummaries.map((questionSummary: QuestionSummary) => {
    if (questionSummary.id.toString() === question.id.toString()) {
      questionSummary.updateDescription(question.description);
      questionSummary.updateScore(question.score);
      questionSummary.updateSectionId(question.sectionId);
    }
    return questionSummary;
  });
  return questionSummariesUpdate;
};

const syncQuestionToLocal = (
  questions: Question<QuestionProps>[],
  newQuestion: Question<QuestionProps>,
): Question<QuestionProps>[] => {
  const updatedQuestions = questions.map((question) => {
    if (question.id.toString() === newQuestion.id.toString()) {
      if (newQuestion.isFillInBlankQuestion()) {
        if (question.isEssayQuestion()) {
          newQuestion.updateExplanation(question.explanation);

          return newQuestion;
        }

        const parsedQuestion = question as FillInBlankQuestion;
        const reCalculateScore = parsedQuestion.answers.length * Question.defaultScore;

        newQuestion.updateScore(reCalculateScore);
        newQuestion.updateExplanation(question.explanation);

        return newQuestion;
      }

      newQuestion.updateScore(question.score);
      newQuestion.updateExplanation(question.explanation);

      return newQuestion;
    }
    return question;
  });
  return updatedQuestions;
};

const deleteQuestions = (questions: Question<QuestionProps>[], questionId: string) => {
  return questions.filter(
    (question: Question<QuestionProps>) => question.id.toString() !== questionId.toString(),
  );
};

const deleteQuestionInSummaries = (
  questionSummaries: QuestionSummary[],
  questionId: string,
): QuestionSummary[] => {
  return questionSummaries.filter(
    (questionSummary: QuestionSummary) => questionSummary.id.toString() !== questionId.toString(),
  );
};

const createAnswerChoiceQuestion = (
  questions: Question<QuestionProps>[],
  newAnswerInput: CreateSingleMultipleChoiceAnswerUseCaseInput,
): Question<QuestionProps>[] => {
  const questionUpdateIndex = questions.findIndex(
    (q) => q.id.toString() === newAnswerInput.questionId,
  );
  const questionUpdate = questions[questionUpdateIndex] as SingleChoiceQuestion;
  const newAnswer = Answer.create({
    id: newAnswerInput.input.id,
    description: newAnswerInput.input.description,
  }).getValue();
  questionUpdate.addNewAnswer(newAnswer);
  return questions;
};

const deleteAnswerChoiceQuestion = (
  questions: Question<QuestionProps>[],
  questionId: string,
  answerId: string,
): Question<QuestionProps>[] => {
  const questionIndex = questions.findIndex(
    (question: SingleChoiceQuestion | MultipleChoiceQuestion) =>
      question.id.toString() === questionId,
  );

  const question = questions[questionIndex] as SingleChoiceQuestion | MultipleChoiceQuestion;
  question.deleteAnswer(answerId);
  return questions;
};

const createAnswerAndCorrectAnswerFillInBlankQuestion = (
  questions: Question<QuestionProps>[],
  newAnswerInput: CreateFillInBlankAnswerUseCaseInput,
): Question<QuestionProps>[] => {
  const questionIndex = questions.findIndex(
    (question: Question<QuestionProps>) => question.id.toString() === newAnswerInput.questionId,
  );
  const newAnswer = Answer.create({
    id: newAnswerInput.input.id,
    description: newAnswerInput.input.description,
  }).getValue();

  const newCorrectAnswer = FillInBlankCorrectAnswer.create({
    id: newAnswerInput.input.correctAnswerId,
    answerId: newAnswerInput.input.id,
    score: 0.5,
    index: newAnswerInput.input.index,
  }).getValue();

  const question = questions[questionIndex] as FillInBlankQuestion;
  question.addNewAnswer(newAnswer);
  question.addNewCorrectAnswer(newCorrectAnswer);

  const reCalculatedScore = question.score + Question.defaultScore;
  question.updateScore(reCalculatedScore);

  return questions;
};

const deleteAnswerFillInBlank = (
  questions: Question<QuestionProps>[],
  deleteAnswerInput: DeleteFillInBlankAnswerUseCaseInput,
): Question<QuestionProps>[] => {
  const questionIndex = questions.findIndex(
    (question: Question<QuestionProps>) => question.id.toString() === deleteAnswerInput.questionId,
  );
  const question = questions[questionIndex] as FillInBlankQuestion;

  const deletedCorrectAnswer = question.correctAnswers.find(
    (c) => c.answerId.toString() === deleteAnswerInput.answerId,
  );
  question.deleteAnswer(deleteAnswerInput.answerId);

  const reCalculatedScore = question.score - deletedCorrectAnswer.score;
  question.updateScore(reCalculatedScore);

  return questions;
};

const updateFillInBlankAnswer = (
  questions: Question<QuestionProps>[],
  updateAnswerInput: UpdateFillInBlankAnswerUseCaseInput,
): Question<QuestionProps>[] => {
  const questionIndex = questions.findIndex(
    (question: Question<QuestionProps>) => question.id.toString() === updateAnswerInput.questionId,
  );
  const question = questions[questionIndex] as FillInBlankQuestion;
  question.updateAnswer(updateAnswerInput.answerId, updateAnswerInput.input.description);
  return questions;
};

const updateFillInBlankCorrectAnswer = (
  questions: Question<QuestionProps>[],
  updateAnswerInput: UpdateFillInBlankCorrectAnswerUseCaseInput,
): Question<QuestionProps>[] => {
  const { correctAnswerId, input, questionId } = updateAnswerInput;

  const updatedQuestions = questions.map((q) => {
    if (q.id.toString() === questionId) {
      const updatedQuestion = q as FillInBlankQuestion;
      updatedQuestion.updateCorrectAnswer({
        answerId: correctAnswerId,
        score: input.score,
      });
      let newScore = 0;
      updatedQuestion.correctAnswers.map((c) => { newScore += c.score });
      updatedQuestion.updateScore(newScore);

      return updatedQuestion;
    }
    return q;
  });

  return updatedQuestions;
};

const updateSingleMultipleChoiceAnswer = (
  questions: Question<QuestionProps>[],
  updateAnswerInput: UpdateSingleMultipleChoiceAnswerUseCaseInput,
) => {
  const questionIndex = questions.findIndex(
    (question: Question<QuestionProps>) => question.id.toString() === updateAnswerInput.questionId,
  );
  const question = questions[questionIndex] as SingleChoiceQuestion | MultipleChoiceQuestion;

  question.updateAnswer(updateAnswerInput.answerId, updateAnswerInput.input.description);
  return questions;
};

const updateSingleMultipleChoiceCorrectAnswer = (
  questions: Question<QuestionProps>[],
  updateCorrectAnswerInput: UpdateSingleMultipleChoiceCorrectAnswerUseCaseInput,
): Question<QuestionProps>[] => {
  const questionIndex = questions.findIndex(
    (question: Question<QuestionProps>) =>
      question.id.toString() === updateCorrectAnswerInput.questionId,
  );
  const questionUpdate = questions[questionIndex] as SingleChoiceQuestion | MultipleChoiceQuestion;
  const { answerIds } = updateCorrectAnswerInput.input;
  questionUpdate.updateCorrectAnswer(answerIds);
  return questions;
};

const updateSectionsFormResponse = (
  formResponse: Form,
  createSectionInput: CreateSectionUseCaseInput,
): Form => {


  const section = Section.create({
    id: createSectionInput.payload.id,
    title: createSectionInput.payload.title,
    score: createSectionInput.payload.score,
    index: formResponse.sections.length,
    description: createSectionInput.payload.description,
    totalQuestion: 0,
  }).getValue();
  formResponse.addNewSection(section);
  return formResponse;
};

const deleteSectionFormResponse = (formResponse: Form, sectionId: string): Form => {
  formResponse.deleteSection(sectionId);
  return formResponse;
};

const updateSectionInformationFormResponse = (
  useCaseInput: UpdateSectionUseCaseInput,
  form: Form,
): Form => {
  const { sectionId, input } = useCaseInput;
  const { title, description } = input;

  const updatedSections = form.sections.map((section) => {
    if (section.id.toString() === sectionId) {
      if (title) {
        section.updateTitle(title);
      }

      if (description) {
        section.updateDescription(description);
      }
    }
    return section;
  });
  form.updateSections(updatedSections);
  return form;
};

const filterConfigs = (data: AssignmentConfigurations): AssignmentConfigurations => {
  const filteredConfigs = {
    ...data,
  } as AssignmentConfigurations;

  return filteredConfigs;
};
export const handleUpdateSingleMultipleChoiceCorrectAnswerUseCase = {
  executing: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { payload } = action;
    const questions = updateSingleMultipleChoiceCorrectAnswer(state.questions, payload);
    return {
      ...state,
      updateSingleMultipleChoiceCorrectAnswerStatus: QuizEditStatus.EXECUTE,
      updateSingleMultipleChoiceCorrectAnswerUseCaseInput: payload,
      questions,
    };
  },
  success: (state: QuizEditPageState): QuizEditPageState => {
    const questions = updateSingleMultipleChoiceCorrectAnswer(
      state.questions,
      state.updateSingleMultipleChoiceCorrectAnswerUseCaseInput,
    );
    return {
      ...state,
      updateSingleMultipleChoiceCorrectAnswerStatus: QuizEditStatus.SUCCESS,
      questions,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      updateSingleMultipleChoiceCorrectAnswerStatus: QuizEditStatus.ERROR,
    };
  },
  reset: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      updateSingleMultipleChoiceCorrectAnswerStatus: QuizEditStatus.RESET,
    };
  },
};

export const handleUpdateQuestionTypeUseCase = {
  executing: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      updateQuestionTypeStatus: QuizEditStatus.EXECUTE,
    };
  },
  success: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { payload } = action;
    const questions = syncQuestionToLocal(state.questions, payload);
    const questionSummaries = updateQuestionInSummaries(state.questionSummaries, payload);
    return {
      ...state,
      questionSummaries,
      updateQuestionTypeStatus: QuizEditStatus.SUCCESS,
      currentlyEditingQuestionId: payload.id.toString(),
      questions,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      updateQuestionTypeStatus: QuizEditStatus.ERROR,
    };
  },
  reset: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      updateQuestionTypeStatus: QuizEditStatus.RESET,
    };
  },
};

export const handleUpdateSingleMultipleChoiceAnswerUseCase = {
  executing: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const input = action.payload as UpdateSingleMultipleChoiceAnswerUseCaseInput;
    const questions = updateSingleMultipleChoiceAnswer(state.questions, input);

    return {
      ...state,
      questions,
      updatingSingleMultipleChoiceAnswerStatus: QuizEditStatus.EXECUTE,
    };
  },
  success: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      updatingSingleMultipleChoiceAnswerStatus: QuizEditStatus.SUCCESS,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      updatingSingleMultipleChoiceAnswerStatus: QuizEditStatus.ERROR,
    };
  },
  reset: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      updatingSingleMultipleChoiceAnswerStatus: QuizEditStatus.RESET,
    };
  },
};

export const handleQuestionFocusUseCase = {
  executing: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { payload } = action;
    return {
      ...state,
      selectedQuestionIndex: payload,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error,
    };
  },
  reset: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      selectedQuestionIndex: null,
    };
  },
};

export const handleGetGradesAndSubjectsUseCase = {
  executing: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      getGradesAndSubjectsStatus: QuizEditStatus.EXECUTE,
    };
  },
  success: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { payload } = action;
    return {
      ...state,
      gradeAndSubjectResponses: payload as [Grade[], Subject[]],
      getGradesAndSubjectsStatus: QuizEditStatus.SUCCESS,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      getGradesAndSubjectsStatus: QuizEditStatus.ERROR,
    };
  },
};

export const handleUpdateFormInFormationUseCase = {
  executing: (state: QuizEditPageState): QuizEditPageState => {
    return { ...state, updateFormInformationStatus: QuizEditStatus.EXECUTE };
  },
  success: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      updateFormInformationStatus: QuizEditStatus.SUCCESS,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      updateFormInformationStatus: QuizEditStatus.ERROR,
    };
  },
};

export const handleFindAllQuestionsUseCase = {
  executing: (state: QuizEditPageState): QuizEditPageState => {
    return { ...state, isLoadingGetQuestions: true };
  },
  success: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { payload } = action;
    const questions = payload as Question<QuestionProps>[];
    const questionSummaries = mapQuestionsToQuestionSummaries(questions);
    const hasEssayOrFillInBlankQuestion = questions.some(
      (question) =>
        question.type === QuestionType.Essay || question.type === QuestionType.FillInBlank,
    );

    return {
      ...state,
      questions,
      // selectedSection,
      questionSummaries,
      hasEssayOrFillInBlankQuestion,
      isLoadingGetQuestions: false,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      isLoadingGetQuestions: false,
    };
  },
};

export const handleFindAllQuestionsByFormIdUseCase = {
  executing: (state: QuizEditPageState): QuizEditPageState => {
    return { ...state, isLoadingGetAllQuestions: true };
  },
  success: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { payload } = action;
    const questions = payload as Question<QuestionProps>[];

    const hasEssayOrFillInBlankQuestion = questions.some(
      (question) =>
        question.type === QuestionType.Essay || question.type === QuestionType.FillInBlank,
    );

    return {
      ...state,
      isLoadingGetAllQuestions: false,
      hasEssayOrFillInBlankQuestion,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      isLoadingGetAllQuestions: false,
    };
  },
};

export const handleCreateLocalQuestionUseCase = {
  executing: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      createLocalQuestionStatus: QuizEditStatus.EXECUTE,
    };
  },
  success: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { payload } = action;
    const questions = [...state.questions, payload];
    const sections = state.sections;
    const matchedSection = sections.find(s => s.id.toString() === payload.sectionId);
    matchedSection.updateTotalQuestions(matchedSection.totalQuestion + 1);
    const questionSummaries = questions.map((question: Question<QuestionProps>) =>
      mapQuestionToQuestionSummaries(question),
    );
    return {
      ...state,
      questions,
      questionSummaries,
      createLocalQuestionStatus: QuizEditStatus.SUCCESS,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      createLocalQuestionStatus: QuizEditStatus.ERROR,
    };
  },
  reset: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      createLocalQuestionStatus: QuizEditStatus.RESET,
    };
  },
};

export const handleCreateQuestionUseCase = {
  executing: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      createQuestionStatus: QuizEditStatus.EXECUTE,
    };
  },
  success: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      createQuestionStatus: QuizEditStatus.SUCCESS,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      createQuestionStatus: QuizEditStatus.ERROR,
    };
  },
  reset: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      createQuestionStatus: QuizEditStatus.RESET,
      createLocalQuestionStatus: QuizEditStatus.RESET,
    };
  },
};

export const handleUpdateQuestionUseCase = {
  executing: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { payload } = action;
    const useCaseInput = payload as UpdateQuestionUseCaseInput;
    const { id, input } = useCaseInput;

    let updatedQuestion: Question<QuestionProps> = null;
    const questions = state.questions.map((question: Question<QuestionProps>) => {
      const isFoundedQuestion = question.id.toString() === id;
      if (isFoundedQuestion) {
        updatedQuestion = question;
        if (input.description) {
          updatedQuestion.updateDescription(input.description);
        }
        if (input.explanation || input.explanation === '') {
          updatedQuestion.updateExplanation(input.explanation);
        }
        if (input.images) {
          updatedQuestion.updateImages(input.images);
        }
        if (input.score) {
          updatedQuestion.updateScore(input.score);
        }
        return updatedQuestion;
      }
      return question;
    });
    const questionSummaries = updatedQuestion && updateQuestionInSummaries(state.questionSummaries, updatedQuestion);
    const hasEssayOrFillInBlankQuestion = questions.some(
      (question) =>
        question.type === QuestionType.Essay || question.type === QuestionType.FillInBlank,
    );

    return {
      ...state,
      questions,
      questionSummaries,
      hasEssayOrFillInBlankQuestion,
      updateQuestionStatus: QuizEditStatus.EXECUTE,
    };
  },
  success: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      updateQuestionStatus: QuizEditStatus.SUCCESS,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      updateQuestionStatus: QuizEditStatus.ERROR,
    };
  },
};

export const handleDeleteQuestionUseCase = {
  executing: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { payload } = action;
    const sectionSummaries = state.sections;
    const updatedSection = sectionSummaries.find(s => s.questions.length !== 0);
    updatedSection.updateTotalQuestions(updatedSection.totalQuestion - 1);

    return {
      ...state,
      questionId: payload,
      deleteQuestionStatus: QuizEditStatus.EXECUTE,
    };
  },
  success: (state: QuizEditPageState): QuizEditPageState => {
    const questions = deleteQuestions(state.questions, state.questionId);
    const questionSummaries = deleteQuestionInSummaries(state.questionSummaries, state.questionId);
    return {
      ...state,
      questions,
      questionSummaries,
      deleteQuestionStatus: QuizEditStatus.SUCCESS,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
    };
  },
  reset: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      deleteQuestionStatus: QuizEditStatus.RESET,
    };
  },
};

export const handleCreateAnswerChoiceQuestionUseCase = {
  executing: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { payload } = action;
    const newAnswerInput = payload as CreateSingleMultipleChoiceAnswerUseCaseInput;
    const isDefined = !!state.newAnswerInput;
    return {
      ...state,
      newAnswerInput: isDefined ?
        [newAnswerInput, ...state.newAnswerInput] :
        [newAnswerInput],
      createAnswerStatus: QuizEditStatus.EXECUTE,
    };
  },
  success: (state: QuizEditPageState): QuizEditPageState => {
    const input = state.newAnswerInput.pop();
    const questions = createAnswerChoiceQuestion(state.questions, input);
    return {
      ...state,
      questions,
      createAnswerStatus: QuizEditStatus.SUCCESS,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
    };
  },
  reset: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      createAnswerStatus: QuizEditStatus.RESET,
    };
  },
};

export const handleDeleteAnswerChoiceQuestionUseCase = {
  executing: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const isInputDefined = !!state.deleteAnswerInput;
    return {
      ...state,
      deleteAnswerInput: isInputDefined ? [action.payload, ...state.deleteAnswerInput] : [action.payload],
      deleteAnswerStatus: QuizEditStatus.EXECUTE,
    };
  },
  success: (state: QuizEditPageState): QuizEditPageState => {
    const { questionId, answerId } = state.deleteAnswerInput.pop();
    const clonedQuestions = [...state.questions];
    const questions = deleteAnswerChoiceQuestion(clonedQuestions, questionId, answerId);

    return {
      ...state,
      questions,
      deleteAnswerStatus: state.deleteAnswerInput.length === 0 ? QuizEditStatus.SUCCESS : QuizEditStatus.EXECUTE,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      deleteAnswerStatus: QuizEditStatus.ERROR,
    };
  },
  reset: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      deleteAnswerStatus: QuizEditStatus.RESET,
    }
  }
};

export const handleCreateFillInBlankAnswerUseCase = {
  executing: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const isDefined = !!state.createFillInBlankAnswerInput;
    return {
      ...state,
      createFillInBlankAnswerInput: isDefined ?
        [action.payload, ...state.createFillInBlankAnswerInput] :
        [action.payload],
      createFillInBlankAnswerStatus: QuizEditStatus.EXECUTE,
    };
  },
  success: (state: QuizEditPageState): QuizEditPageState => {
    const input = state.createFillInBlankAnswerInput.pop();
    const questions = createAnswerAndCorrectAnswerFillInBlankQuestion(
      state.questions,
      input
    );
    const updatedQuestion = questions.find(q => q.id.toString() === input.questionId);
    const questionSummaries = updateQuestionInSummaries(state.questionSummaries, updatedQuestion);

    return {
      ...state,
      questions: questions,
      questionSummaries,
      createFillInBlankAnswerStatus: QuizEditStatus.SUCCESS,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
    };
  },
  reset: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      createFillInBlankAnswerStatus: QuizEditStatus.RESET,
    };
  },
};

export const handleUpdateFillInBlankAnswerUseCase = {
  executing: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    return {
      ...state,
      updateFillInBlankAnswerInput: action.payload,
      updateFillInBlankAnswerStatus: QuizEditStatus.EXECUTE,
    };
  },
  success: (state: QuizEditPageState): QuizEditPageState => {
    const questions = updateFillInBlankAnswer(state.questions, state.updateFillInBlankAnswerInput);
    return {
      ...state,
      questions,
      updateFillInBlankAnswerStatus: QuizEditStatus.SUCCESS,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      updateFillInBlankAnswerStatus: QuizEditStatus.ERROR,
    };
  },
};

export const handleUpdateFillInBlankCorrectAnswerUseCase = {
  executing: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    return {
      ...state,
      updateFillInBlankCorrectAnswerInput: action.payload,
      updateFillInBlankCorrectAnswerStatus: QuizEditStatus.EXECUTE,
    };
  },
  success: (state: QuizEditPageState): QuizEditPageState => {
    const questions = updateFillInBlankCorrectAnswer(
      state.questions,
      state.updateFillInBlankCorrectAnswerInput,
    );
    const updatedQuestion = questions.find(
      q => q.id.toString() === state.updateFillInBlankCorrectAnswerInput.questionId
    )
    const questionSummaries = updateQuestionInSummaries(state.questionSummaries, updatedQuestion);

    return {
      ...state,
      questions,
      questionSummaries,
      updateFillInBlankCorrectAnswerStatus: QuizEditStatus.SUCCESS,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      updateFillInBlankCorrectAnswerStatus: QuizEditStatus.ERROR,
    };
  },
  reset: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      updateFillInBlankCorrectAnswerStatus: QuizEditStatus.RESET,
    };
  },
};

export const handleDeleteFillInBlankAnswerUseCase = {
  executing: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const isInputDefined = !!state.deleteFillInBlankAnswerInput;
    return {
      ...state,
      deleteFillInBlankAnswerInput: isInputDefined ?
        [action.payload, ...state.deleteFillInBlankAnswerInput] :
        [action.payload],
      deleteFillInBlankAnswerStatus: QuizEditStatus.EXECUTE,
    };
  },
  success: (state: QuizEditPageState): QuizEditPageState => {
    const input = state.deleteFillInBlankAnswerInput.pop();
    const questions = deleteAnswerFillInBlank(state.questions, input);
    const updatedQuestion = questions.find(q => q.id.toString() === input.questionId);
    const questionSummaries = updateQuestionInSummaries(state.questionSummaries, updatedQuestion);
    return {
      ...state,
      questions,
      questionSummaries,
      deleteFillInBlankAnswerStatus: state.deleteFillInBlankAnswerInput.length === 0 ?
        QuizEditStatus.SUCCESS :
        QuizEditStatus.EXECUTE,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      deleteFillInBlankAnswerStatus: QuizEditStatus.ERROR,
    };
  },
  reset: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      deleteFillInBlankAnswerStatus: QuizEditStatus.RESET,
    };
  },
};

export const handleFindSectionSummariesUseCase = {
  executing: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      findSectionSummariesStatus: QuizEditStatus.EXECUTE,
    };
  },
  success: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const sections = action.payload as SectionSummary[];
    const [selectedSection] = sections;
    const [selectedSectionDetail] = state.formResponse.sections;

    const defaultSelectedSectionIndex = 1;

    return {
      ...state,
      sections,
      selectedSection,
      selectedSectionIndex: defaultSelectedSectionIndex,
      selectedSectionDetail,
      findSectionSummariesStatus: QuizEditStatus.SUCCESS,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      findSectionSummariesStatus: QuizEditStatus.ERROR,
    };
  },
};

export const handleChangeSelectedSection = {
  executing: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { payload } = action;
    const newSelectedSection = state.sections.find(
      (section) => section.id.toString() === payload.sectionId,
    );



    const selectedSectionDetail = state.formResponse.sections.find(
      (section) => section.id.toString() === newSelectedSection.id.toString(),
    );


    return {
      ...state,
      selectedSection: newSelectedSection,
      selectedSectionDetail,
      selectedSectionIndex: payload.sectionSelectedIndex,
    };
  },
};

export const handleFindFormByIdUseCase = {
  executing: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      findFormByIdStatus: QuizEditStatus.EXECUTE,
    };
  },
  success: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    return {
      ...state,
      formResponse: action.payload,
      sectionQuantity: action.payload.sections.length,
      findFormByIdStatus: QuizEditStatus.SUCCESS,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      findFormByIdStatus: QuizEditStatus.ERROR,
    };
  },
};

export const handleCreateSectionUseCase = {
  executing: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const input = action.payload as CreateSectionUseCaseInput;

    const sections = createSection(input, state.sections);
    const formResponse = updateSectionsFormResponse(state.formResponse, input);
    const selectedSectionDetail = formResponse.sections[sections.length - 1];

    return {
      ...state,
      sections,
      selectedSection: sections[sections.length - 1],
      formResponse,
      selectedSectionDetail,
      selectedSectionIndex: sections.length,
      sectionQuantity: sections.length,
      createSectionStatus: QuizEditStatus.EXECUTE,
    };
  },
  success: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      createSectionStatus: QuizEditStatus.SUCCESS,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      createSectionStatus: QuizEditStatus.ERROR,
    };
  },
};

export const handleUpdateSectionUseCase = {
  executing: (state: QuizEditPageState, action: Action) => {
    const useCaseInput = action.payload as UpdateSectionUseCaseInput;

    const sections = updateSection(useCaseInput, state.sections);
    const form = updateSectionInformationFormResponse(useCaseInput, state.formResponse);


    return {
      ...state,
      sections,
      formResponse: form,
      updateSectionStatus: QuizEditStatus.EXECUTE,
    };
  },
  success: (state: QuizEditPageState) => {
    return {
      ...state,
      updateSectionStatus: QuizEditStatus.SUCCESS,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      updateSectionStatus: QuizEditStatus.ERROR,
    };
  },
};

export const handleDeleteSectionUseCase = {
  executing: (state: QuizEditPageState, action: Action) => {
    return {
      ...state,
      deleteSectionId: action.payload.sectionId,
      deleteSectionStatus: QuizEditStatus.EXECUTE,
    };
  },
  success: (state: QuizEditPageState) => {
    const sections = deleteSection(state.deleteSectionId, state.sections);
    const formResponse = deleteSectionFormResponse(state.formResponse, state.deleteSectionId);
    const selectedSectionIndex =
      state.selectedSectionIndex === state.sectionQuantity
        ? state.selectedSectionIndex - 1
        : state.selectedSectionIndex;

    const selectedSection = sections[selectedSectionIndex - 1];
    const selectedSectionDetail = formResponse.sections[selectedSectionIndex - 1];
    return {
      ...state,
      formResponse,
      sections,
      selectedSection,
      selectedSectionIndex,
      selectedSectionDetail,
      sectionQuantity: sections.length,
      deleteSectionStatus: QuizEditStatus.SUCCESS,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      deleteSectionStatus: QuizEditStatus.ERROR,
    };
  },
};

export const handleGetAssignmentUseCase = {
  executing: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      isLoadingGetAssignments: true,
      getAssignmentSummaryStatus: REQUEST_STATUS.DEFAULT,
    };
  },
  success: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { payload } = action;

    return {
      ...state,
      assignmentResponses: payload as AssignmentSummary[],
      getAssignmentSummaryStatus: REQUEST_STATUS.SUCCESS,
      isLoadingGetAssignments: false,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      isLoadingGetAssignments: false,
      getAssignmentSummaryStatus: REQUEST_STATUS.FAIL,
    };
  },
};

export const handleGetAssignmentConfigurationsUseCase = {
  executing: (state: QuizEditPageState): QuizEditPageState => {
    return { ...state, isLoadingAssignmentDataConfigs: true };
  },
  success: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { payload } = action;
    const assignmentDataConfigs = filterConfigs(payload);
    return {
      ...state,
      assignmentConfigsResponse: assignmentDataConfigs,
      isLoadingAssignmentDataConfigs: false,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return { ...state, error: error, isLoadingAssignmentDataConfigs: false };
  },
};

export const handleDeleteAssignmentUseCase = {
  executing: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { payload } = action;
    return {
      ...state,
      deletedAssignmentId: payload as string,
      deleteAssignmentStatus: REQUEST_STATUS.DEFAULT,
    };
  },
  success: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      deleteAssignmentStatus: REQUEST_STATUS.SUCCESS,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return { ...state, error: error, deleteAssignmentStatus: REQUEST_STATUS.FAIL };
  },
  reset: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      deleteAssignmentStatus: REQUEST_STATUS.DEFAULT,
    };
  },
};

export const handleCreatePracticeAssignmentUseCase = {
  executing: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      createAssignmentStatus: QuizEditStatus.EXECUTE,
    };
  },
  success: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { payload } = action;

    return {
      ...state,
      createAssignmentResponse: payload as CreateAssignmentResponse,
      createAssignmentStatus: QuizEditStatus.SUCCESS,
    };
  },
  error: (state: QuizEditPageState, action: Action): QuizEditPageState => {
    const { error } = action;
    return {
      ...state,
      createAssignmentStatus: QuizEditStatus.ERROR,
      error: error,
    };
  },
  reset: (state: QuizEditPageState): QuizEditPageState => {
    return {
      ...state,
      createAssignmentStatus: QuizEditStatus.RESET,
    };
  },
};
