import {
  IQuestionRepository,
  FindQuestionsUseCase,
  QUESTION_REPOSITORY,
  UpdateQuestionUseCase,
  DeleteQuestionUseCase,
  FindQuestionsSummaryUseCase,
  CreateQuestionUseCase,
  FIND_ALL_QUESTIONS_USE_CASE,
  CREATE_QUESTION_USE_CASE,
  DELETE_QUESTION_USE_CASE,
  UPDATE_QUESTION_USE_CASE,
  FIND_QUESTION_SUMMARIES_USE_CASE,
  UPDATE_QUESTION_TYPE_USE_CASE,
  FIND_ALL_QUESTIONS_USE_CASE_BY_FORM_ID,
} from '@module/form';

export const questionUseCaseProviders = [
  {
    provide: FIND_ALL_QUESTIONS_USE_CASE,
    useFactory: (questionRepository: IQuestionRepository) => {
      return new FindQuestionsUseCase(questionRepository);
    },
    inject: [QUESTION_REPOSITORY],
  },
  {
    provide: FIND_ALL_QUESTIONS_USE_CASE_BY_FORM_ID,
    useFactory: (questionRepository: IQuestionRepository) => {
      return new FindQuestionsUseCase(questionRepository);
    },
    inject: [QUESTION_REPOSITORY],
  },
  {
    provide: UPDATE_QUESTION_TYPE_USE_CASE,
    useFactory: (questionRepository: IQuestionRepository) => {
      return new CreateQuestionUseCase(questionRepository);
    },
    inject: [QUESTION_REPOSITORY],
  },
  {
    provide: CREATE_QUESTION_USE_CASE,
    useFactory: (questionRepository: IQuestionRepository) => {
      return new CreateQuestionUseCase(questionRepository);
    },
    inject: [QUESTION_REPOSITORY],
  },
  {
    provide: UPDATE_QUESTION_USE_CASE,
    useFactory: (questionRepository: IQuestionRepository) => {
      return new UpdateQuestionUseCase(questionRepository);
    },
    inject: [QUESTION_REPOSITORY],
  },
  {
    provide: DELETE_QUESTION_USE_CASE,
    useFactory: (questionRepository: IQuestionRepository) => {
      return new DeleteQuestionUseCase(questionRepository);
    },
    inject: [QUESTION_REPOSITORY],
  },
  {
    provide: FIND_QUESTION_SUMMARIES_USE_CASE,
    useFactory: (questionRepository: IQuestionRepository) => {
      return new FindQuestionsSummaryUseCase(questionRepository);
    },
    inject: [QUESTION_REPOSITORY],
  },
];
