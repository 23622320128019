import {
  IFolderRepository,
  CreateFolderUseCase,
  FOLDER_REPOSITORY,
  UpdateFolderUseCase,
  DeleteFolderUseCase,
  CREATE_FOLDER_USE_CASE,
  UPDATE_FOLDER_USE_CASE,
  DELETE_FOLDER_USE_CASE,
} from '@module/form';

export const folderUseCaseProviders = [
  {
    provide: CREATE_FOLDER_USE_CASE,
    useFactory: (folderRepository: IFolderRepository) => {
      return new CreateFolderUseCase(folderRepository);
    },
    inject: [FOLDER_REPOSITORY],
  },
  {
    provide: UPDATE_FOLDER_USE_CASE,
    useFactory: (folderRepository: IFolderRepository) => {
      return new UpdateFolderUseCase(folderRepository);
    },
    inject: [FOLDER_REPOSITORY],
  },
  {
    provide: DELETE_FOLDER_USE_CASE,
    useFactory: (folderRepository: IFolderRepository) => {
      return new DeleteFolderUseCase(folderRepository);
    },
    inject: [FOLDER_REPOSITORY],
  },
];
