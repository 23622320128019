import { createReducer } from '@lib/plugin-redux-core';
import {
  FIND_ALL_QUESTIONS_USE_CASE,
  FIND_FORM_BY_ID_USE_CASE,
  GET_SECTION_SUMMARIES_USE_CASE,
  Form,
  Section,
  SectionSummary,
} from '@module/form';
import { Question, QuestionProps } from '@module/form';
import { UseCaseError } from '@smart-form/domains';
import { UPDATE_SELECTED_SECTION } from 'redux/root.action';
import {
  handleChangeSelectedSection,
  handleFindAllQuestionsUseCase,
  handleFindFormByIdUseCase,
  handleFindSectionSummariesUseCase,
} from './quiz-preview.handler';

export type QuizPreviewPageInitialState = {
  error?: UseCaseError<any>;
  activeKey: number[];
};

export type FindFormByIdUseCaseState = {
  form: Form;
  isLoadingFindForm: boolean;
};

export type FindAllQuestionUseCaseState = {
  questions: Question<QuestionProps>[];
  isLoadingFindQuestions: boolean;
};

export type FindSectionSummariesUseCaseState = {
  sectionSummaries: SectionSummary[];
  isLoadSectionSummaries: boolean;
};

export type ChangeSelectedSection = {
  selectedSection: Section;
  selectedSectionIndex: number;
};

export type ChangeSectionPanelActiveKey = {};

export type QuizPreviewPageState = QuizPreviewPageInitialState &
  FindFormByIdUseCaseState &
  FindAllQuestionUseCaseState &
  ChangeSelectedSection &
  FindSectionSummariesUseCaseState &
  ChangeSectionPanelActiveKey;

const defaultActiveKey = 0;

export const initialState: QuizPreviewPageInitialState = {
  error: null,
  activeKey: [defaultActiveKey],
};

export const quizPreviewReducer = createReducer(initialState, [
  {
    name: FIND_FORM_BY_ID_USE_CASE,
    ...handleFindFormByIdUseCase,
  },
  {
    name: FIND_ALL_QUESTIONS_USE_CASE,
    ...handleFindAllQuestionsUseCase,
  },
  {
    name: UPDATE_SELECTED_SECTION,
    ...handleChangeSelectedSection,
  },
  {
    name: GET_SECTION_SUMMARIES_USE_CASE,
    ...handleFindSectionSummariesUseCase,
  },
]);
