import { SubmissionWebCached, SUBMISSION_WEB_CACHED } from '@module/assignment';
import {
  QuestionReleasesWebCached,
  QUESTION_RELEASES_WEB_CACHED,
  GRADE_WEB_CACHED,
  GradeWebCached,
  SUBJECT_WEB_CACHED,
  SubjectWebCached,
} from '@module/form';

export const cachedProviders = [
  {
    provide: GRADE_WEB_CACHED,
    useFactory: () => {
      return new GradeWebCached();
    },
  },
  {
    provide: SUBJECT_WEB_CACHED,
    useFactory: () => {
      return new SubjectWebCached();
    },
  },
  {
    provide: QUESTION_RELEASES_WEB_CACHED,
    useFactory: () => {
      return new QuestionReleasesWebCached();
    },
  },
  {
    provide: SUBMISSION_WEB_CACHED,
    useFactory: () => {
      return new SubmissionWebCached();
    },
  },
];
