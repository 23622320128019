import { createReducer } from '@lib/plugin-redux-core';
import {
  CreateFillInBlankAnswerUseCaseInput,
  CreateSectionUseCaseInput,
  CreateSingleMultipleChoiceAnswerUseCaseInput,
  CREATE_ESSAY_QUESTION_USE_CASE,
  CREATE_FILL_IN_BLANK_ANSWER_USE_CASE,
  CREATE_FILL_IN_BLANK_QUESTION_USE_CASE,
  CREATE_MULTIPLE_CHOICE_QUESTION_USE_CASE,
  CREATE_QUESTION_USE_CASE,
  CREATE_SECTION_USE_CASE,
  CREATE_SINGLE_CHOICE_QUESTION_USE_CASE,
  CREATE_SINGLE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
  DeleteFillInBlankAnswerUseCaseInput,
  DeleteSingleMultipleChoiceAnswerUseCaseInput,
  DELETE_FILL_IN_BLANK_ANSWER_USE_CASE,
  DELETE_QUESTION_USE_CASE,
  DELETE_SECTION_USE_CASE,
  DELETE_SINGLE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
  FIND_ALL_QUESTIONS_USE_CASE,
  FIND_FORM_BY_ID_USE_CASE,
  GET_SECTION_SUMMARIES_USE_CASE,
  Form,
  GET_GRADES_AND_SUBJECTS_USE_CASE,
  SectionSummary,
  UpdateFillInBlankAnswerUseCaseInput,
  UpdateQuestionUseCaseInput,
  UpdateSingleMultipleChoiceAnswerUseCaseInput,
  UpdateSingleMultipleChoiceCorrectAnswerUseCaseInput,
  UpdateSectionInput,
  UPDATE_FILL_IN_BLANK_ANSWER_USE_CASE,
  UPDATE_FORM_INFORMATION_USE_CASE,
  UPDATE_QUESTION_TYPE_USE_CASE,
  UPDATE_QUESTION_USE_CASE,
  UPDATE_SINGLE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
  UPDATE_SINGLE_MULTIPLE_CHOICE_CORRECT_ANSWER_USE_CASE,
  UPDATE_SECTION_USE_CASE,
  Section,
  FIND_ALL_QUESTIONS_USE_CASE_BY_FORM_ID,
  UPDATE_FILL_IN_BLANK_CORRECT_ANSWER_USE_CASE,
  UpdateFillInBlankCorrectAnswerUseCaseInput,
  SingleChoiceQuestion,
  MultipleChoiceQuestion,
  EssayQuestion,
  FillInBlankQuestion,
} from '@module/form';
import { UPDATE_SELECTED_SECTION_QUIZ_EDIT, QUESTION_FOCUS_USE_CASE } from '../root.action';
import {
  handleChangeSelectedSection,
  handleCreateAnswerChoiceQuestionUseCase,
  handleCreateFillInBlankAnswerUseCase,
  handleCreateLocalQuestionUseCase,
  handleCreatePracticeAssignmentUseCase,
  // handleCreatePracticeAssignmentUseCase,
  handleCreateQuestionUseCase,
  handleCreateSectionUseCase,
  handleDeleteAnswerChoiceQuestionUseCase,
  handleDeleteAssignmentUseCase,
  handleDeleteFillInBlankAnswerUseCase,
  handleDeleteQuestionUseCase,
  handleDeleteSectionUseCase,
  handleFindAllQuestionsUseCase,
  handleFindFormByIdUseCase,
  handleFindSectionSummariesUseCase,
  handleGetAssignmentConfigurationsUseCase,
  handleGetAssignmentUseCase,
  // handleGetAssignmentConfigurationsUseCase,
  // handleGetAssignmentUseCase,
  handleGetGradesAndSubjectsUseCase,
  handleQuestionFocusUseCase,
  handleUpdateFillInBlankAnswerUseCase,
  handleUpdateFormInFormationUseCase,
  handleUpdateQuestionTypeUseCase,
  handleUpdateQuestionUseCase,
  handleUpdateSingleMultipleChoiceAnswerUseCase,
  handleUpdateSingleMultipleChoiceCorrectAnswerUseCase,
  handleUpdateSectionUseCase,
  handleFindAllQuestionsByFormIdUseCase,
  handleUpdateFillInBlankCorrectAnswerUseCase,
} from './quiz-edit.handler';
import { Grade, QuestionSummary, Subject } from '@module/form';
import { UseCaseError } from '@smart-form/domains';
import {
  AssignmentConfigurations,
  AssignmentSummary,
  CreateAssignmentResponse,
  CREATE_PRACTICE_ASSIGNMENT_USE_CASE,
  DELETE_ASSIGNMENT_USE_CASE,
  GET_ASSIGNMENTS_USE_CASE,
  GET_ASSIGNMENT_CONFIGURATIONS_USE_CASE,
} from '@module/assignment';

export const enum QuizEditStatus {
  'EXECUTE' = 1,
  'SUCCESS' = 2,
  'ERROR' = 3,
  'RESET' = 4,
}

export type QuizEditPageInitialState = {
  error?: UseCaseError<any>;
  questions: (
    | SingleChoiceQuestion
    | MultipleChoiceQuestion
    | EssayQuestion
    | FillInBlankQuestion
  )[];
  questionSummaries: QuestionSummary[];
};

export type CreateAssignmentUseCaseState = {
  createAssignmentResponse: CreateAssignmentResponse;
  createAssignmentStatus: QuizEditStatus;
  effect: number;
};

export type GetGradesAndSubjectsUseCaseState = {
  gradeAndSubjectResponses: [Grade[], Subject[]];
  getGradesAndSubjectsStatus: QuizEditStatus;
};

type UpdateSingleMultipleChoiceAnswerUseCase = {
  updatingSingleMultipleChoiceAnswerStatus: QuizEditStatus;
  updateSingleMultipleChoiceAnswerUseCaseInput: UpdateSingleMultipleChoiceAnswerUseCaseInput;
};

type UpdateSingleMultipleChoiceCorrectAnswerUseCase = {
  updateSingleMultipleChoiceCorrectAnswerStatus: QuizEditStatus;
  updateSingleMultipleChoiceCorrectAnswerUseCaseInput: UpdateSingleMultipleChoiceCorrectAnswerUseCaseInput;
};

type UpdateFormInformationUseCaseState = {
  updateFormInformationStatus: QuizEditStatus;
};

type CreateLocalQuestionUseCaseState = {
  createLocalQuestionStatus: QuizEditStatus;
};

type CreateQuestionUseCaseState = {
  createQuestionStatus: QuizEditStatus;
};

type UpdateQuestionTypeUseCase = {
  updateQuestionTypeStatus: QuizEditStatus;
  currentlyEditingQuestionId: string;
};

type FindAllQuestionsUseCaseState = {
  isLoadingGetQuestions: boolean;
  hasEssayOrFillInBlankQuestion: boolean;
};

type FindAllQuestionsByFormIdUseCaseState = {
  isLoadingGetAllQuestions: boolean;
  hasEssayOrFillInBlankQuestion: boolean;
};

type UpdateQuestionUseCaseState = {
  questionUpdate: UpdateQuestionUseCaseInput;
  updateQuestionStatus: QuizEditStatus;
};

type DeleteQuestionUseCaseState = {
  deleteQuestionStatus: QuizEditStatus;
  questionId: string;
};

type CreateSingleMultipleChoiceAnswerUseCaseState = {
  newAnswerInput: CreateSingleMultipleChoiceAnswerUseCaseInput[];
  createAnswerStatus: QuizEditStatus;
};

type DeleteAnswerUseCaseState = {
  deleteAnswerStatus: QuizEditStatus;
  deleteAnswerInput: DeleteSingleMultipleChoiceAnswerUseCaseInput[];
};

type CreateFillInBlankAnswerUseCaseState = {
  createFillInBlankAnswerStatus: QuizEditStatus;
  createFillInBlankAnswerInput: CreateFillInBlankAnswerUseCaseInput[];
};

type UpdateFillInBlankAnswerUseCaseState = {
  updateFillInBlankAnswerStatus: QuizEditStatus;
  updateFillInBlankAnswerInput: UpdateFillInBlankAnswerUseCaseInput;
};

type UpdateFillInBlankCorrectAnswerUseCaseState = {
  updateFillInBlankCorrectAnswerInput: UpdateFillInBlankCorrectAnswerUseCaseInput;
  updateFillInBlankCorrectAnswerStatus: QuizEditStatus;
};

type DeleteFillInBlankAnswerUseCaseState = {
  deleteFillInBlankAnswerInput: DeleteFillInBlankAnswerUseCaseInput[];
  deleteFillInBlankAnswerStatus: QuizEditStatus;
};

type SelectQuestionUseCaseState = {
  selectedQuestionIndex: number;
};

type FindSectionSummariesUseCaseState = {
  findSectionSummariesStatus: QuizEditStatus;
  sections: SectionSummary[];
};

type ChangeSelectedSectionUseCaseState = {
  selectedSection: SectionSummary;
  selectedSectionIndex: number;
  selectedSectionDetail: Section;
};

type FindFormByIdUseCaseState = {
  findFormByIdStatus: QuizEditStatus;
  formResponse: Form;
  sectionQuantity: number;
};

type CreateSectionUseCaseState = {
  createSectionInput: CreateSectionUseCaseInput;
  createSectionStatus: QuizEditStatus;
};

type UpdateSectionUseCaseState = {
  updateSectionStatus: QuizEditStatus;
  updateSectionInput: UpdateSectionInput;
};

type DeleteSectionUseCaseState = {
  deleteSectionStatus: QuizEditStatus;
  deleteSectionId: string;
};

export type GetAssignmentConfigurationUseCaseState = {
  assignmentConfigsResponse: AssignmentConfigurations;
  isLoadingAssignmentDataConfigs: boolean;
};

export type GetAssignmentsUseCaseState = {
  assignmentResponses: AssignmentSummary[];
  isLoadingGetAssignments: boolean;
  getAssignmentSummaryStatus: number;
};

export type DeleteAssignmentUseCaseState = {
  // effect: number;
  isLoading: boolean;
  deletedAssignmentId: string;
  deleteAssignmentStatus: number;
};

export type QuizEditPageState = QuizEditPageInitialState &
  // Create
  CreateLocalQuestionUseCaseState &
  CreateQuestionUseCaseState &
  CreateSectionUseCaseState &
  CreateSingleMultipleChoiceAnswerUseCaseState &
  CreateFillInBlankAnswerUseCaseState &
  // Delete
  DeleteQuestionUseCaseState &
  DeleteSectionUseCaseState &
  DeleteAnswerUseCaseState &
  DeleteFillInBlankAnswerUseCaseState &
  //Get - Find
  FindFormByIdUseCaseState &
  FindSectionSummariesUseCaseState &
  SelectQuestionUseCaseState &
  FindAllQuestionsUseCaseState &
  GetGradesAndSubjectsUseCaseState &
  FindAllQuestionsByFormIdUseCaseState &
  // Update
  UpdateQuestionUseCaseState &
  UpdateQuestionTypeUseCase &
  ChangeSelectedSectionUseCaseState &
  UpdateSectionUseCaseState &
  UpdateFillInBlankAnswerUseCaseState &
  UpdateFillInBlankCorrectAnswerUseCaseState &
  UpdateSingleMultipleChoiceAnswerUseCase &
  UpdateSingleMultipleChoiceCorrectAnswerUseCase &
  UpdateFormInformationUseCaseState &
  CreateAssignmentUseCaseState &
  UpdateQuestionUseCaseState &
  CreateSingleMultipleChoiceAnswerUseCaseState &
  DeleteQuestionUseCaseState &
  DeleteAnswerUseCaseState &
  CreateFillInBlankAnswerUseCaseState &
  DeleteFillInBlankAnswerUseCaseState &
  SelectQuestionUseCaseState &
  FindSectionSummariesUseCaseState &
  // ChangeSectionSelectedUseCaseState &
  FindFormByIdUseCaseState &
  CreateSectionUseCaseState &
  GetAssignmentsUseCaseState &
  DeleteAssignmentUseCaseState &
  UpdateSectionUseCaseState &
  GetAssignmentConfigurationUseCaseState &
  DeleteSectionUseCaseState;

export const initialState: QuizEditPageInitialState = {
  error: null,
  questions: [],
  questionSummaries: [],
};

export const quizEditReducer = createReducer(initialState, [
  {
    name: CREATE_SECTION_USE_CASE,
    ...handleCreateSectionUseCase,
  },
  {
    name: CREATE_QUESTION_USE_CASE,
    ...handleCreateQuestionUseCase,
  },
  {
    name: CREATE_SINGLE_CHOICE_QUESTION_USE_CASE,
    ...handleCreateLocalQuestionUseCase,
  },
  {
    name: CREATE_MULTIPLE_CHOICE_QUESTION_USE_CASE,
    ...handleCreateLocalQuestionUseCase,
  },
  {
    name: CREATE_FILL_IN_BLANK_QUESTION_USE_CASE,
    ...handleCreateLocalQuestionUseCase,
  },
  {
    name: CREATE_ESSAY_QUESTION_USE_CASE,
    ...handleCreateLocalQuestionUseCase,
  },
  {
    name: CREATE_SINGLE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
    ...handleCreateAnswerChoiceQuestionUseCase,
  },
  {
    name: CREATE_FILL_IN_BLANK_ANSWER_USE_CASE,
    ...handleCreateFillInBlankAnswerUseCase,
  },
  {
    name: UPDATE_SECTION_USE_CASE,
    ...handleUpdateSectionUseCase,
  },
  {
    name: UPDATE_FORM_INFORMATION_USE_CASE,
    ...handleUpdateFormInFormationUseCase,
  },
  {
    name: UPDATE_QUESTION_USE_CASE,
    ...handleUpdateQuestionUseCase,
  },
  {
    name: UPDATE_QUESTION_TYPE_USE_CASE,
    ...handleUpdateQuestionTypeUseCase,
  },
  {
    name: UPDATE_SINGLE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
    ...handleUpdateSingleMultipleChoiceAnswerUseCase,
  },
  {
    name: UPDATE_FILL_IN_BLANK_ANSWER_USE_CASE,
    ...handleUpdateFillInBlankAnswerUseCase,
  },
  {
    name: UPDATE_FILL_IN_BLANK_CORRECT_ANSWER_USE_CASE,
    ...handleUpdateFillInBlankCorrectAnswerUseCase,
  },
  {
    name: UPDATE_SINGLE_MULTIPLE_CHOICE_CORRECT_ANSWER_USE_CASE,
    ...handleUpdateSingleMultipleChoiceCorrectAnswerUseCase,
  },
  {
    name: UPDATE_SELECTED_SECTION_QUIZ_EDIT,
    ...handleChangeSelectedSection,
  },
  {
    name: FIND_FORM_BY_ID_USE_CASE,
    ...handleFindFormByIdUseCase,
  },
  {
    name: GET_SECTION_SUMMARIES_USE_CASE,
    ...handleFindSectionSummariesUseCase,
  },
  {
    name: GET_GRADES_AND_SUBJECTS_USE_CASE,
    ...handleGetGradesAndSubjectsUseCase,
  },
  {
    name: FIND_ALL_QUESTIONS_USE_CASE,
    ...handleFindAllQuestionsUseCase,
  },
  {
    name: FIND_ALL_QUESTIONS_USE_CASE_BY_FORM_ID,
    ...handleFindAllQuestionsByFormIdUseCase,
  },
  {
    name: DELETE_SECTION_USE_CASE,
    ...handleDeleteSectionUseCase,
  },
  {
    name: DELETE_QUESTION_USE_CASE,
    ...handleDeleteQuestionUseCase,
  },
  {
    name: DELETE_SINGLE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
    ...handleDeleteAnswerChoiceQuestionUseCase,
  },
  {
    name: DELETE_FILL_IN_BLANK_ANSWER_USE_CASE,
    ...handleDeleteFillInBlankAnswerUseCase,
  },
  {
    name: QUESTION_FOCUS_USE_CASE,
    ...handleQuestionFocusUseCase,
  },
  // Assignment
  {
    name: CREATE_PRACTICE_ASSIGNMENT_USE_CASE,
    ...handleCreatePracticeAssignmentUseCase,
  },
  {
    name: GET_ASSIGNMENTS_USE_CASE,
    ...handleGetAssignmentUseCase,
  },
  {
    name: GET_ASSIGNMENT_CONFIGURATIONS_USE_CASE,
    ...handleGetAssignmentConfigurationsUseCase,
  },
  {
    name: DELETE_ASSIGNMENT_USE_CASE,
    ...handleDeleteAssignmentUseCase,
  },
]);
