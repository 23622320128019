import {
  IFormRepository,
  CreateFormUseCase,
  FORM_REPOSITORY,
  UpdateFormUseCase,
  FindFormByIdUseCase,
  DeleteFormUseCase,
  CreateSectionUseCase,
  DeleteSectionUseCase,
  UpdateSectionUseCase,
  GetSectionSummariesUseCase,
  CreateEssayQuestionUseCase,
  CreateFillInBlankQuestionUseCase,
  CreateSingleChoiceQuestionUseCase,
  CreateMultipleChoiceQuestionUseCase,
  CREATE_SINGLE_CHOICE_QUESTION_USE_CASE,
  CREATE_ESSAY_QUESTION_USE_CASE,
  CREATE_MULTIPLE_CHOICE_QUESTION_USE_CASE,
  CREATE_FILL_IN_BLANK_QUESTION_USE_CASE,
  FIND_FORM_BY_ID_USE_CASE,
  CREATE_FORM_USE_CASE,
  UPDATE_FORM_INFORMATION_USE_CASE,
  DELETE_FORM_USE_CASE,
  CREATE_SECTION_USE_CASE,
  UPDATE_SECTION_USE_CASE,
  DELETE_SECTION_USE_CASE,
  GET_SECTION_SUMMARIES_USE_CASE,
  UPDATE_FORM_TITLE_USE_CASE,
} from '@module/form';
import { Provider } from '@nestjs/common';

export const formUseCaseProviders: Partial<Provider>[] = [
  {
    provide: FIND_FORM_BY_ID_USE_CASE,
    useFactory: (formRepository: IFormRepository) => {
      return new FindFormByIdUseCase(formRepository);
    },
    inject: [FORM_REPOSITORY],
  },
  {
    provide: CREATE_FORM_USE_CASE,
    useFactory: (formRepository: IFormRepository) => {
      return new CreateFormUseCase(formRepository);
    },
    inject: [FORM_REPOSITORY],
  },
  {
    provide: UPDATE_FORM_TITLE_USE_CASE,
    useFactory: (formRepository: IFormRepository) => {
      return new UpdateFormUseCase(formRepository);
    },
    inject: [FORM_REPOSITORY],
  },
  {
    provide: UPDATE_FORM_INFORMATION_USE_CASE,
    useFactory: (formRepository: IFormRepository) => {
      return new UpdateFormUseCase(formRepository);
    },
    inject: [FORM_REPOSITORY],
  },
  {
    provide: DELETE_FORM_USE_CASE,
    useFactory: (formRepository: IFormRepository) => {
      return new DeleteFormUseCase(formRepository);
    },
    inject: [FORM_REPOSITORY],
  },
  {
    provide: CREATE_SECTION_USE_CASE,
    useFactory: (formRepository: IFormRepository) => {
      return new CreateSectionUseCase(formRepository);
    },
    inject: [FORM_REPOSITORY],
  },
  {
    provide: UPDATE_SECTION_USE_CASE,
    useFactory: (formRepository: IFormRepository) => {
      return new UpdateSectionUseCase(formRepository);
    },
    inject: [FORM_REPOSITORY],
  },
  {
    provide: DELETE_SECTION_USE_CASE,
    useFactory: (formRepository: IFormRepository) => {
      return new DeleteSectionUseCase(formRepository);
    },
    inject: [FORM_REPOSITORY],
  },
  {
    provide: GET_SECTION_SUMMARIES_USE_CASE,
    useFactory: (formRepository: IFormRepository) => {
      return new GetSectionSummariesUseCase(formRepository);
    },
    inject: [FORM_REPOSITORY],
  },
  {
    provide: CREATE_ESSAY_QUESTION_USE_CASE,
    useClass: CreateEssayQuestionUseCase,
  },
  {
    provide: CREATE_FILL_IN_BLANK_QUESTION_USE_CASE,
    useClass: CreateFillInBlankQuestionUseCase,
  },
  {
    provide: CREATE_SINGLE_CHOICE_QUESTION_USE_CASE,
    useClass: CreateSingleChoiceQuestionUseCase,
  },
  {
    provide: CREATE_MULTIPLE_CHOICE_QUESTION_USE_CASE,
    useClass: CreateMultipleChoiceQuestionUseCase,
  },
];
