import { createReducer } from '@lib/plugin-redux-core';
import {
  CREATE_FOLDER_USE_CASE,
  CREATE_FORM_USE_CASE,
  DELETE_FOLDER_USE_CASE,
  DELETE_FORM_USE_CASE,
  GET_GRADES_AND_SUBJECTS_USE_CASE,
  Grade,
  SpaceObjects,
  Subject,
  UpdateFolderUseCaseInput,
  UpdateFormUseCaseInput,
  UPDATE_FOLDER_USE_CASE,
  FolderSummary,
  FormSummary,
  UPDATE_FORM_TITLE_USE_CASE,
  SEARCH_SPACE_OBJECTS_USE_CASE,
  GET_FOLDER_BY_ID_USE_CASE,
} from '@module/form';
import { UseCaseError } from '@smart-form/domains';
import {
  handleCreateFolderUseCase,
  handleCreateFormUseCase,
  handleDeleteFolderUseCase,
  handleDeleteFormUseCase,
  handleGetFolderByIdUseCase,
  handleGetGradesAndSubjectsUseCase,
  handleSearchSpaceObjectsUseCase,
  handleUpdateFolderUseCase,
  handleUpdateFormTitleUseCase,
} from './space.handler';

export const enum SpacePageStatus {
  EXECUTE,
  SUCCESS,
  ERROR,
  RESET,
}

export type SpacePageInitialState = {
  error?: UseCaseError<any>;
};

//UseCases State ---------------
export type SearchSpaceObjectsUseCaseStates = {
  formsAndFoldersSearchOptions: SpaceObjects;
  searchFormsAndFoldersStatus: SpacePageStatus;
};

export type GetGradesAndSubjectsUseCaseStates = {
  gradesAndSubjects: [Grade[], Subject[]];
};

export type CreateFolderUseCaseState = {
  createdFolderSummary: FolderSummary;
  createFolderStatus: SpacePageStatus;
};

export type UpdateFolderUseCaseState = {
  updatedFolderSummary: UpdateFolderUseCaseInput;
  updateFolderSummaryStatus: SpacePageStatus;
};

export type GetFolderByIdUseCase = {
  spaceObjects: SpaceObjects;
  getFolderByIdStatus: SpacePageStatus;
};

export type DeleteFolderUseCaseState = {
  deletedFolderId: string;
  deleteFolderStatus: SpacePageStatus;
};

export type CreateFormUseCaseState = {
  createdFormSummary: FormSummary;
  createFormStatus: SpacePageStatus;
};

export type UpdateFormUseCaseState = {
  updatedFormSummary: UpdateFormUseCaseInput;
  updateFormSummaryStatus: SpacePageStatus;
};

export type DeleteFormUseCaseState = {
  deletedFormId: string;
  deleteFormStatus: SpacePageStatus;
};

type FolderUseCasesStates = CreateFolderUseCaseState &
  UpdateFolderUseCaseState &
  DeleteFolderUseCaseState &
  GetFolderByIdUseCase;

type FormUseCasesStates = CreateFormUseCaseState & UpdateFormUseCaseState & DeleteFormUseCaseState;

export type SpacePageState = SpacePageInitialState &
  GetGradesAndSubjectsUseCaseStates &
  SearchSpaceObjectsUseCaseStates &
  FolderUseCasesStates &
  FormUseCasesStates;

export const initialState: SpacePageState = {
  error: null,
  spaceObjects: null,
  searchFormsAndFoldersStatus: null,
  formsAndFoldersSearchOptions: null,
  getFolderByIdStatus: null,
  updatedFolderSummary: null,
  deletedFolderId: '',
  updateFolderSummaryStatus: null,
  updateFormSummaryStatus: null,
  deleteFolderStatus: null,
  createdFormSummary: null,
  updatedFormSummary: null,
  deletedFormId: '',
  createdFolderSummary: null,
  createFolderStatus: null,
  createFormStatus: null,
  deleteFormStatus: null,
  gradesAndSubjects: null,
};

export const LOADING_SPACE = 'LOADING_SPACE';
export const spaceObjectsReducer = createReducer(initialState, [
  {
    name: LOADING_SPACE,
    executing: (state: SpacePageState): SpacePageState => {
      return {
        ...state,
        getFolderByIdStatus: SpacePageStatus.EXECUTE,
      };
    },
  },
  {
    name: SEARCH_SPACE_OBJECTS_USE_CASE,
    ...handleSearchSpaceObjectsUseCase,
  },
  {
    name: GET_FOLDER_BY_ID_USE_CASE,
    ...handleGetFolderByIdUseCase,
  },
  {
    name: GET_GRADES_AND_SUBJECTS_USE_CASE,
    ...handleGetGradesAndSubjectsUseCase,
  },
  {
    name: CREATE_FOLDER_USE_CASE,
    ...handleCreateFolderUseCase,
  },
  {
    name: UPDATE_FOLDER_USE_CASE,
    ...handleUpdateFolderUseCase,
  },
  {
    name: DELETE_FOLDER_USE_CASE,
    ...handleDeleteFolderUseCase,
  },
  {
    name: CREATE_FORM_USE_CASE,
    ...handleCreateFormUseCase,
  },
  {
    name: UPDATE_FORM_TITLE_USE_CASE,
    ...handleUpdateFormTitleUseCase,
  },
  {
    name: DELETE_FORM_USE_CASE,
    ...handleDeleteFormUseCase,
  },
]);
