/* eslint-disable @next/next/no-script-component-in-head */
/* eslint-disable react/jsx-no-comment-textnodes */
import { LoadingPage, NetworkErrorPage } from '@lib/common';
import 'antd/dist/antd.css';
import { NextPage } from 'next';
import { appWithTranslation, i18n } from 'next-i18next';
import App, { AppProps, AppContext } from 'next/app';
import Head from 'next/head';
import { withRouter } from 'next/router';
import React, { ReactNode } from 'react';
import { runAsyncEpics, runAuthAsyncEpics } from '../redux/root.epic';
import { wrapper } from '../redux/store';
import { Analytics } from '@vercel/analytics/react';
import Script from 'next/script';
import getConfig from 'next/config';

// Global CSS
import 'katex/dist/katex.min.css';
import 'mathquill/build/mathquill.css';
import '@module/form-components/src/rich-text-editor/math4quill.css';
import 'react-quill/dist/quill.snow.css';

interface State {
  isOnline: boolean;
}
type Page<P = {}> = NextPage<P> & {
  getLayout?: (page: ReactNode) => ReactNode;
};

type Props = AppProps & {
  Component: Page;
};
class MyApp extends App<Props, {}, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOnline: true,
    };
    this.getLayout = this.props.Component.getLayout ?? ((page: ReactNode) => page);
  }
  public getLayout;

  public static getInitialProps = wrapper.getInitialAppProps(() => async (context) => {
    return {
      pageProps: {
        ...(await App.getInitialProps(context)).pageProps,
      },
    };
  });

  async componentDidMount() {
    const isRunningClientSide = process.browser;
    if (isRunningClientSide) {
      if (localStorage.getItem('access_token')) {
        await runAsyncEpics();
      } else {
        await runAuthAsyncEpics();
      }
    }
    this.setState({ isOnline: navigator.onLine });
  }

  render() {
    const { Component, pageProps } = this.props;
    const isRunningClientSide = process.browser;
    const { publicRuntimeConfig } = getConfig();

    const renderPageDetail = () =>
      this.state.isOnline ? (
        <>
          <Head>
            <link rel="shortcut icon" href="/images/Favicon.svg" />
            <title>{i18n?.t('appTitle')}</title>
            <Script
              src="https://www.googletagmanager.com/gtag/js?id=G-N384LX2WR2"
              strategy="afterInteractive"
            />
          </Head>
          <Script
              id="ms_clarity"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
               (function(c,l,a,r,i,t,y){
                  c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                  t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                  y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "${publicRuntimeConfig.env.CLARITY_PROJECT_ID}");`,
              }}
            />
          {this.getLayout(<Component {...pageProps} />)}
          <Analytics />
        </>
      ) : (
        <NetworkErrorPage />
      );

    const renderPageOnBrowser = (): ReactNode =>
      isRunningClientSide ? renderPageDetail() : <LoadingPage />;

    return <>{renderPageOnBrowser()}</>;
  }
}
MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  if (appContext.ctx.res?.statusCode === 404) {
    appContext.ctx.res.writeHead(302, { Location: '/' });
    appContext.ctx.res.end();
    return;
  }

  return { ...appProps };
};

const MyAppTranslationWrapper = appWithTranslation(MyApp);
export default withRouter(wrapper.withRedux(MyAppTranslationWrapper));
