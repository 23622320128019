import {
  AssignmentApi,
  ASSIGNMENT_API,
  AssignmentConfigurationApi,
  ASSIGNMENT_CONFIGURATION_API,
  SubmissionApi,
  SUBMISSION_API,
  PRACTICE_ASSIGNMENT_API,
  PracticeAssignmentApi,
  PRACTICE_SUBMISSION_API,
  PracticeSubmissionApi,
} from '@module/assignment';

import {
  FormApi,
  FolderApi,
  QuestionApi,
  FillInBlankQuestionApi,
  SingleMultipleChoiceQuestionApi,
  GradeApi,
  SubjectApi,
  FILL_IN_BLANK_QUESTION_API,
  FOLDER_API,
  FORM_API,
  GRADE_API,
  QUESTION_API,
  SINGLE_MULTIPLE_CHOICE_QUESTION_API,
  SUBJECT_API,
  SPACE_SEARCH_API,
  SpaceObjectsApi,
  QUESTION_RELEASES_API,
  QuestionReleasesApi,
  FormReleaseApi,
  FORM_RELEASE_API,
} from '@module/form';
import { AuthApi, AUTH_API, UserApi, USER_API } from '@module/user';
import { AxiosInstanceBuilder } from '@smart-form/plugin-axios';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const timeout = publicRuntimeConfig.api.timeout;

export const authApiProvider = {
  provide: AUTH_API,
  useFactory: () => {
    return new AuthApi(
      new AxiosInstanceBuilder()
        .withBaseUrl(publicRuntimeConfig.api.baseIdentityUrl)
        .withTimeout(timeout)
        .toInstance(),
    );
  },
};

export const apiProviders = [
  authApiProvider,
  {
    provide: USER_API,
    useFactory: () => {
      return new UserApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseIdentityUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: FORM_API,
    useFactory: () => {
      return new FormApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: FORM_RELEASE_API,
    useFactory: () => {
      return new FormReleaseApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: FOLDER_API,
    useFactory: () => {
      return new FolderApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: QUESTION_API,
    useFactory: () => {
      return new QuestionApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: FILL_IN_BLANK_QUESTION_API,
    useFactory: () => {
      return new FillInBlankQuestionApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: SINGLE_MULTIPLE_CHOICE_QUESTION_API,
    useFactory: () => {
      return new SingleMultipleChoiceQuestionApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: SPACE_SEARCH_API,
    useFactory: () => {
      return new SpaceObjectsApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: GRADE_API,
    useFactory: () => {
      return new GradeApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: SUBJECT_API,
    useFactory: () => {
      return new SubjectApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: ASSIGNMENT_API,
    useFactory: () => {
      return new AssignmentApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: PRACTICE_ASSIGNMENT_API,
    useFactory: () => {
      return new PracticeAssignmentApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: ASSIGNMENT_CONFIGURATION_API,
    useFactory: () => {
      return new AssignmentConfigurationApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: SUBMISSION_API,
    useFactory: () => {
      return new SubmissionApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: PRACTICE_SUBMISSION_API,
    useFactory: () => {
      return new PracticeSubmissionApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseAssignmentUrl)
          .withTimeout(timeout)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
  {
    provide: QUESTION_RELEASES_API,
    useFactory: () => {
      return new QuestionReleasesApi(
        new AxiosInstanceBuilder()
          .withBaseUrl(publicRuntimeConfig.api.baseFormUrl)
          .withTimeout(3000)
          .withHeaders({
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          })
          .toInstance(),
      );
    },
  },
];
