import {
  GetGradesAndSubjectsUseCase,
  GET_GRADES_AND_SUBJECTS_USE_CASE,
  GRADE_REPOSITORY,
  IGradeRepository,
  ISubjectRepository,
  SUBJECT_REPOSITORY,
  GET_FOLDER_BY_ID_USE_CASE,
  SEARCH_SPACE_OBJECTS_USE_CASE,
  SPACE_REPOSITORY,
  ISpaceObjectsRepository,
  SearchSpaceObjectsUseCase,
} from '@module/form';

export const spaceObjectsSearchUseCaseProviders: any = [
  {
    provide: SEARCH_SPACE_OBJECTS_USE_CASE,
    useFactory: (spaceObjectsRepository: ISpaceObjectsRepository) => {
      return new SearchSpaceObjectsUseCase(spaceObjectsRepository)
    },
    inject: [SPACE_REPOSITORY],
  },
  {
    provide: GET_FOLDER_BY_ID_USE_CASE,
    useFactory: (spaceObjectsRepository: ISpaceObjectsRepository) => {
      return new SearchSpaceObjectsUseCase(spaceObjectsRepository);
    },
    inject: [SPACE_REPOSITORY],
  },
  {
    provide: GET_GRADES_AND_SUBJECTS_USE_CASE,
    useFactory: (
      gradeRepository: IGradeRepository,
      subjectRepository: ISubjectRepository,
    ) => {
      return new GetGradesAndSubjectsUseCase(
        gradeRepository,
        subjectRepository,
      );
    },
    inject: [GRADE_REPOSITORY, SUBJECT_REPOSITORY],
  },
];
