import { Action } from '@lib/plugin-redux-core';
import { Form, Question, QuestionProps, Section, SectionSummary } from '@module/form';
import { QuizPreviewPageState } from './quiz-preview.reducer';

export const handleFindAllQuestionsUseCase = {
  executing: (state: QuizPreviewPageState): QuizPreviewPageState => {
    return { ...state, isLoadingFindQuestions: true };
  },
  success: (state: QuizPreviewPageState, action: Action): QuizPreviewPageState => {
    const { payload } = action;
    const questions = payload as Question<QuestionProps>[];

    return {
      ...state,
      questions,
      isLoadingFindQuestions: false,
    };
  },
  error: (state: QuizPreviewPageState, action: Action): QuizPreviewPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      isLoadingFindQuestions: false,
    };
  },
};

export const handleFindSectionSummariesUseCase = {
  executing: (state: QuizPreviewPageState): QuizPreviewPageState => {
    return { ...state, isLoadSectionSummaries: true };
  },
  success: (state: QuizPreviewPageState, action: Action): QuizPreviewPageState => {
    const { payload } = action;
    const sectionSummaries = payload as SectionSummary[];

    return {
      ...state,
      sectionSummaries,
      isLoadSectionSummaries: false,
    };
  },
  error: (state: QuizPreviewPageState, action: Action): QuizPreviewPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      isLoadSectionSummaries: false,
    };
  },
};

export const handleFindFormByIdUseCase = {
  executing: (state: QuizPreviewPageState): QuizPreviewPageState => {
    return { ...state, isLoadingFindForm: true };
  },
  success: (state: QuizPreviewPageState, action: Action): QuizPreviewPageState => {
    const { payload } = action;
    const form = payload as Form;
    const defaultSelectedSectionIndex = 0;

    return {
      ...state,
      form,
      selectedSection: form.sections[defaultSelectedSectionIndex],
      selectedSectionIndex: defaultSelectedSectionIndex,
      isLoadingFindForm: false,
    };
  },
  error: (state: QuizPreviewPageState, action: Action): QuizPreviewPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      isLoadingFindForm: false,
    };
  },
};

export const handleChangeSelectedSection = {
  executing: (state: QuizPreviewPageState, action: Action): QuizPreviewPageState => {
    const { payload } = action;
    return {
      ...state,
      selectedSection: payload.selectedSection as Section,
      selectedSectionIndex: payload.selectedSectionIndex as number,
    };
  },
};
