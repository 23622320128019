import {
  ISubmissionRepository,
  SUBMISSION_REPOSITORY,
  GetSubmissionsUseCase,
  GET_SUBMISSIONS_USE_CASE,
  GET_ATTENDEE_SUBMISSIONS_USE_CASE,
  GetAttendeeSubmissionsUseCase,
  IAssignmentRepository,
  ASSIGNMENT_REPOSITORY,
  GET_SUBMISSION_BY_ID_USE_CASE,
  GetSubmissionByIdUseCase,
  GET_ANSWERS_USE_CASE,
  GetAnswersUseCase,
} from '@module/assignment';
import { IQuestionReleasesRepository, QUESTION_RELEASES_REPOSITORY } from '@module/form';

export const submissionUseCaseProviders = [
  {
    provide: GET_SUBMISSIONS_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new GetSubmissionsUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: GET_SUBMISSION_BY_ID_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new GetSubmissionByIdUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: GET_ANSWERS_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new GetAnswersUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: GET_ATTENDEE_SUBMISSIONS_USE_CASE,
    useFactory: (
      assignmentRepository: IAssignmentRepository,
      submissionRepository: ISubmissionRepository,
      questionReleasesRepository: IQuestionReleasesRepository,
    ) => {
      return new GetAttendeeSubmissionsUseCase(
        assignmentRepository,
        submissionRepository,
        questionReleasesRepository,
      );
    },
    inject: [ASSIGNMENT_REPOSITORY, SUBMISSION_REPOSITORY, QUESTION_RELEASES_REPOSITORY],
  },
];
