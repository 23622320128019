import {
  AssignmentConfigurations,
  AssignmentSummary,
  Attendee,
  AttendeeSubmission,
  PracticeAssignment,
} from '@module/assignment';
import { RootReducerState } from '../../redux/root.reducer';

export const assignmentResponseSelector = (
  state: RootReducerState,
): AssignmentSummary[] => state.assignment.assignmentResponses || [];

export const getAssignmentSummaryStatusSelector = (
  state: RootReducerState,
): number => state.assignment.getAssignmentSummaryStatus;
export const loadingSelector = (state: RootReducerState): boolean =>
  state.assignment.isLoading;

export const loadingGetByIdSelector = (state: RootReducerState): boolean =>
  state.assignment.isLoadingGetById;

export const assignmentConfigsResponseSelector = (
  state: RootReducerState,
): AssignmentConfigurations => state.assignment.assignmentConfigsResponse;

export const loadingConfigsSelector = (state: RootReducerState): boolean =>
  state.assignment.isLoadingConfigs;

export const assignmentByIdResponseSelector = (
  state: RootReducerState,
): PracticeAssignment => state.assignment.assignmentByIdResponse;

export const getAssignmentByIdStatusSelector = (
  state: RootReducerState,
): number => state.assignment.getAssignmentByIdStatus;

export const effectSelector = (state: RootReducerState): number =>
  state.assignment.effect;

export const deleteAssignmentStatusSelector = (
  state: RootReducerState,
): number => state.assignment.deleteAssignmentStatus;
// Attendee
export const attendeeResponsesSelector = (
  state: RootReducerState,
): Attendee[] => state.assignment.attendeeResponses || [];

export const loadingGetAttendeesSelector = (state: RootReducerState): boolean =>
  state.assignment.isLoadingGetAttendees;

export const getAttendeeStatusSelector = (
  state: RootReducerState,
): number => state.assignment.statusGetAttendee;

// Submissions By Attendee
export const loadingAttendeeSubmissionSelector = (
  state: RootReducerState,
): boolean => state.assignment.isLoadingAttendeeSubmission;

export const statusGetSubmissionSelector = (state: RootReducerState): number =>
  state.assignment.statusGetAttendeeSubmission;

export const attendeeSubmissionResponseSelector = (
  state: RootReducerState,
): AttendeeSubmission => state.assignment.attendeeSubmissionResponse;
