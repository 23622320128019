import {
  AssignmentConfigurations,
  AssignmentSummary,
  Attendee,
  AttendeeSubmission,
  DELETE_ASSIGNMENT_USE_CASE,
  GET_ASSIGNMENTS_USE_CASE,
  GET_ASSIGNMENT_BY_ID_USE_CASE,
  GET_ASSIGNMENT_CONFIGURATIONS_USE_CASE,
  GET_ATTENDEES_USE_CASE,
  GET_ATTENDEE_SUBMISSIONS_USE_CASE,
  PracticeAssignment,
} from '@module/assignment';
import { createReducer } from '@lib/plugin-redux-core';
import { UseCaseError } from '@smart-form/domains';
import {
  handleDeleteAssignmentUseCase,
  handleGetAssignmentByIdUseCase,
  handleGetAssignmentConfigurationsUseCase,
  handleGetAssignmentUseCase,
  handleGetAttendeeSubmissionUseCase,
  handleGetAttendeesUseCase,
} from './assignment.handler';

export type AssignmentInitialState = {
  error?: UseCaseError<any>;
  effect: number;
};
export type GetAssignmentsUseCaseState = {
  assignmentResponses: AssignmentSummary[];
  isLoading: boolean;
  getAssignmentSummaryStatus: number;
};
export type DeleteAssignmentUseCaseState = {
  // effect: number;
  deleteAssignmentStatus: number;
  isLoading: boolean;
  deletedAssignmentId: string;
};
export type GetAssignmentConfigurationUseCaseState = {
  assignmentConfigsResponse: AssignmentConfigurations;
  isLoadingConfigs: boolean;
};
export type GetAssignmentByIdUseCaseState = {
  assignmentByIdResponse: PracticeAssignment;
  getAssignmentByIdStatus: number;
  isLoadingGetById: boolean;
};
export type GetAttendeesUseCaseType = {
  attendeeResponses: Attendee[];
  isLoadingGetAttendees: boolean;
  statusGetAttendee: number;
};
export type GetAttendeeSubmissionsUseCaseState = {
  attendeeSubmissionResponse: AttendeeSubmission;
  isLoadingAttendeeSubmission: boolean;
  statusGetAttendeeSubmission: number;
};
export type AssignmentState = AssignmentInitialState &
  GetAssignmentsUseCaseState &
  DeleteAssignmentUseCaseState &
  GetAssignmentConfigurationUseCaseState &
  GetAssignmentByIdUseCaseState &
  GetAttendeesUseCaseType &
  GetAttendeeSubmissionsUseCaseState;

const initialState: AssignmentInitialState = {
  error: null,
  effect: 0,
};

export const assignmentReducer = createReducer(initialState, [
  {
    name: GET_ASSIGNMENTS_USE_CASE,
    ...handleGetAssignmentUseCase,
  },
  {
    name: DELETE_ASSIGNMENT_USE_CASE,
    ...handleDeleteAssignmentUseCase,
  },
  {
    name: GET_ASSIGNMENT_CONFIGURATIONS_USE_CASE,
    ...handleGetAssignmentConfigurationsUseCase,
  },
  {
    name: GET_ASSIGNMENT_BY_ID_USE_CASE,
    ...handleGetAssignmentByIdUseCase,
  },
  {
    name: GET_ATTENDEES_USE_CASE,
    ...handleGetAttendeesUseCase,
  },
  {
    name: GET_ATTENDEE_SUBMISSIONS_USE_CASE,
    ...handleGetAttendeeSubmissionUseCase,
  },
]);
