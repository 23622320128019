import { Module } from '@nestjs/common';
import { useCaseProviders } from './usecase-providers';
import { authenticationRepositoryProvider, userWebRepositoryProvider } from '@module/user';
import {
  formWebRepositoryProvider,
  folderWebRepositoryProvider,
  questionWebRepositoryProvider,
  fillInBlankQuestionWebRepositoryProvider,
  singleMultipleChoiceQuestionWebRepositoryProvider,
  gradeRepositoryProvider,
  subjectRepositoryProvider,
  spaceWebRepositoryProvider,
  questionReleasesWebRepositoryProvider,
  formReleaseWebRepositoryProvider,
} from '@module/form';
import { apiProviders } from './api-providers';
import {
  assignmentConfigurationRepositoryProvider,
  assignmentRepositoryProvider,
  practiceAssignmentRepositoryProvider,
  submissionRepositoryProvider,
} from '@module/assignment';
import { cachedProviders } from './cached-providers';

@Module({
  providers: [
    ...cachedProviders,
    ...apiProviders,
    authenticationRepositoryProvider,
    formWebRepositoryProvider,
    folderWebRepositoryProvider,
    questionWebRepositoryProvider,
    userWebRepositoryProvider,
    spaceWebRepositoryProvider,
    fillInBlankQuestionWebRepositoryProvider,
    singleMultipleChoiceQuestionWebRepositoryProvider,
    gradeRepositoryProvider,
    subjectRepositoryProvider,
    assignmentRepositoryProvider,
    practiceAssignmentRepositoryProvider,
    assignmentConfigurationRepositoryProvider,
    submissionRepositoryProvider,
    questionReleasesWebRepositoryProvider,
    formReleaseWebRepositoryProvider,
    questionReleasesWebRepositoryProvider,
    ...useCaseProviders,
  ],
  exports: [...useCaseProviders],
})
export class AppModule {}
