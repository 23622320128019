import { createReducer, Action } from "@lib/plugin-redux-core";
import { GET_ACCESS_TOKEN_USE_CASE, SIGN_OUT_USE_CASE } from "@module/user";

const initialState = {
  isLoading: false,
  isSignOutSuccess: false,
  tokenResponse: {},
  error: {},
};

export const authReducer = createReducer(initialState, [
  {
    name: SIGN_OUT_USE_CASE,
    executing: (state: {}) => {
      return { ...state };
    },
    success: () => ({ ...initialState, isSignOutSuccess: true }),
    error: (state: {}, action: Action) => {
      const { error } = action;
      return {
        ...state,
        error: error,
        isSignOutSuccess: false,
      };
    },
  },
  {
    name: GET_ACCESS_TOKEN_USE_CASE,
    executing: (state: {}, action: Action) => {
      const { payload } = action;
      return { ...state, payload, isLoading: true };
    },
    success: (state: {}, action: Action) => {
      const { payload } = action;

      return {
        ...state,
        tokenResponse: payload,
        isLoading: false,
      };
    },
    error: (state: {}, action: Action) => {
      const { error } = action;

      return {
        ...state,
        error: error,
        isLoading: false,
      };
    },
  },
]);
