import {
  Form,
  Question,
  QuestionProps,
  Section,
  SectionSummary,
} from '@module/form';
import { RootReducerState } from 'redux/root.reducer';

export const questionsSelector = (
  state: RootReducerState,
): Question<QuestionProps>[] => state.quizPreview.questions;

export const loadingQuestionsSelector = (state: RootReducerState): boolean =>
  state.quizPreview.isLoadingFindQuestions;

export const formSelector = (state: RootReducerState): Form =>
  state.quizPreview.form;

export const loadingFormSelector = (state: RootReducerState): boolean =>
  state.quizPreview.isLoadingFindForm;

export const selectedSectionSelector = (state: RootReducerState): Section =>
  state.quizPreview.selectedSection;

export const selectedSectionIndexSelector = (state: RootReducerState): number =>
  state.quizPreview.selectedSectionIndex;

export const sectionSummariesSelector = (
  state: RootReducerState,
): SectionSummary[] => state.quizPreview.sectionSummaries;

export const activeKeySelector = (state: RootReducerState): number[] =>
  state.quizPreview.activeKey;
