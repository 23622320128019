import {
  FindFormReleaseByIdUseCase,
  FORM_RELEASE_REPOSITORY,
  IFormReleaseRepository,
  FIND_FORM_RELEASE_BY_ID_USE_CASE,
} from '@module/form';

export const formReleaseUseCaseProviders = [
  {
    provide: FIND_FORM_RELEASE_BY_ID_USE_CASE,
    useFactory: (FormReleaseRepository: IFormReleaseRepository) => {
      return new FindFormReleaseByIdUseCase(FormReleaseRepository);
    },
    inject: [FORM_RELEASE_REPOSITORY],
  },
];
