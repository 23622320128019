import {
  ASSIGNMENT_CONFIGURATION_REPOSITORY,
  GetAssignmentConfigurationsUseCase,
  GET_ASSIGNMENT_CONFIGURATIONS_USE_CASE,
  IAssignmentConfigurationRepository,
} from '@module/assignment';

export const assignmentConfigurationUseCaseProviders = [
  {
    provide: GET_ASSIGNMENT_CONFIGURATIONS_USE_CASE,
    useFactory: (
      assignmentConfigurationRepository: IAssignmentConfigurationRepository,
    ) => {
      return new GetAssignmentConfigurationsUseCase(
        assignmentConfigurationRepository,
      );
    },
    inject: [ASSIGNMENT_CONFIGURATION_REPOSITORY],
  },
];
