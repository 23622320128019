import { Action } from "@lib/plugin-redux-core";
import { SubmissionSummary } from "@module/assignment";
import { REQUEST_STATUS } from "../../utils";
import { SubmissionState } from "./submission.reducer";

export const handleGetSubmissionUseCase = {
  executing: (state: SubmissionState): SubmissionState => {
    return {
      ...state,
      status: REQUEST_STATUS.DEFAULT,
      isLoading: true,
    };
  },
  success: (state: SubmissionState, action: Action): SubmissionState => {
    const { payload } = action;
    return {
      ...state,
      submissionResponse: payload as SubmissionSummary[],
      status: REQUEST_STATUS.SUCCESS,
      isLoading: false,
    };
  },
  error: (state: {}, action: Action) => {
    const { error } = action;
    return {
      ...state,
      error: error,
      status: REQUEST_STATUS.FAIL,
      isLoading: false,
    };
  },
}