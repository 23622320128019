import { Action } from '@lib/plugin-redux-core';
import {
  Assignment,
  AssignmentProps,
  AttendeeDetail,
  EssayAnswer,
  FillInBlankGroupAnswer,
  MultipleChoiceAnswer,
  SingleChoiceAnswer,
} from '@module/assignment';
import { FormRelease, Question, QuestionProps } from '@module/form';
import {
  SubmissionPreviewPageState,
  SubmissionPreviewPageStatus,
} from './submission-preview.reducer';

export const handleGetSubmissionById = {
  executing: (state: SubmissionPreviewPageState): SubmissionPreviewPageState => {
    return {
      ...state,
      getSubmissionByIdStatus: SubmissionPreviewPageStatus.EXECUTING,
    };
  },
  success: (state: SubmissionPreviewPageState, action: Action): SubmissionPreviewPageState => {
    const { payload } = action;
    return {
      ...state,
      submission: payload,
      getSubmissionByIdStatus: SubmissionPreviewPageStatus.SUCCESS,
    };
  },
  error: (state: SubmissionPreviewPageState, action: Action): SubmissionPreviewPageState => {
    const { error } = action;
    return {
      ...state,
      getSubmissionByIdStatus: SubmissionPreviewPageStatus.ERROR,
      error,
    };
  },
  reset: (state: SubmissionPreviewPageState): SubmissionPreviewPageState => {
    return {
      ...state,
      getSubmissionByIdStatus: SubmissionPreviewPageStatus.RESET,
    };
  },
};

export const handleGetAssignmentByIdUseCase = {
  executing: (state: SubmissionPreviewPageState): SubmissionPreviewPageState => {
    return {
      ...state,
      getAssignmentByIdStatus: SubmissionPreviewPageStatus.EXECUTING,
    };
  },
  success: (state: SubmissionPreviewPageState, action: Action): SubmissionPreviewPageState => {
    const { payload } = action;
    return {
      ...state,
      assignment: payload as Assignment<AssignmentProps>,
      getAssignmentByIdStatus: SubmissionPreviewPageStatus.SUCCESS,
    };
  },
  error: (state: SubmissionPreviewPageState, action: Action): SubmissionPreviewPageState => {
    const { error } = action;
    return {
      ...state,
      getAssignmentByIdStatus: SubmissionPreviewPageStatus.ERROR,
      error,
    };
  },
  reset: (state: SubmissionPreviewPageState): SubmissionPreviewPageState => {
    return {
      ...state,
      getAssignmentByIdStatus: SubmissionPreviewPageStatus.RESET,
    };
  },
};

export const handleGetFormReleaseByIdUseCase = {
  executing: (state: SubmissionPreviewPageState, action: Action): SubmissionPreviewPageState => {
    const { payload } = action;
    return {
      ...state,
      getFormReleaseByIdStatus: SubmissionPreviewPageStatus.EXECUTING,
    };
  },
  success: (state: SubmissionPreviewPageState, action: Action): SubmissionPreviewPageState => {
    const { payload } = action;

    return {
      ...state,
      formRelease: payload as FormRelease,
      getFormReleaseByIdStatus: SubmissionPreviewPageStatus.SUCCESS,
    };
  },
  error: (state: SubmissionPreviewPageState, action: Action): SubmissionPreviewPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      getFormReleaseByIdStatus: SubmissionPreviewPageStatus.ERROR,
    };
  },
  reset: (state: SubmissionPreviewPageState): SubmissionPreviewPageState => {
    return {
      ...state,
      getFormReleaseByIdStatus: SubmissionPreviewPageStatus.RESET,
    };
  },
};

export const handleGetAttendeeByIdUseCase = {
  executing: (state: SubmissionPreviewPageState): SubmissionPreviewPageState => {
    return {
      ...state,
      getAttendeeByIdStatus: SubmissionPreviewPageStatus.EXECUTING,
    };
  },
  success: (state: SubmissionPreviewPageState, action: Action): SubmissionPreviewPageState => {
    const { payload } = action;
    return {
      ...state,
      getAttendeeByIdStatus: SubmissionPreviewPageStatus.SUCCESS,
      attendeeDetail: payload as AttendeeDetail,
    };
  },
  error: (state: SubmissionPreviewPageState, action: Action): SubmissionPreviewPageState => {
    const { error } = action;
    return {
      ...state,
      getAttendeeByIdStatus: SubmissionPreviewPageStatus.ERROR,
      error,
    };
  },
  reset: (state: SubmissionPreviewPageState): SubmissionPreviewPageState => {
    return {
      ...state,
      getAttendeeByIdStatus: SubmissionPreviewPageStatus.RESET,
    };
  },
};

export const handleGetQuestionReleasesUseCase = {
  executing: (state: SubmissionPreviewPageState): SubmissionPreviewPageState => {
    return {
      ...state,
      getQuestionReleasesStatus: SubmissionPreviewPageStatus.EXECUTING,
    };
  },
  success: (state: SubmissionPreviewPageState, action: Action): SubmissionPreviewPageState => {
    const { payload } = action;
    return {
      ...state,
      questionReleases: payload as Question<QuestionProps>[],
      getQuestionReleasesStatus: SubmissionPreviewPageStatus.SUCCESS,
    };
  },
  error: (state: SubmissionPreviewPageState, action: Action): SubmissionPreviewPageState => {
    const { error } = action;
    return {
      ...state,
      getQuestionReleasesStatus: SubmissionPreviewPageStatus.ERROR,
      error,
    };
  },
  reset: (state: SubmissionPreviewPageState): SubmissionPreviewPageState => {
    return {
      ...state,
      getQuestionReleasesStatus: SubmissionPreviewPageStatus.RESET,
    };
  },
};

export const handleGetAnswersUseCase = {
  executing: (state: SubmissionPreviewPageState): SubmissionPreviewPageState => {
    return {
      ...state,
      getAnswersStatus: SubmissionPreviewPageStatus.EXECUTING,
    };
  },
  success: (state: SubmissionPreviewPageState, action: Action): SubmissionPreviewPageState => {
    const { payload } = action;

    return {
      ...state,
      answers: payload as (
        | EssayAnswer
        | SingleChoiceAnswer
        | FillInBlankGroupAnswer
        | MultipleChoiceAnswer
      )[],
      getAnswersStatus: SubmissionPreviewPageStatus.SUCCESS,
    };
  },
  error: (state: SubmissionPreviewPageState, action: Action): SubmissionPreviewPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      getAnswersStatus: SubmissionPreviewPageStatus.ERROR,
    };
  },
};
