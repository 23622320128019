import { NestFactory } from '@nestjs/core';
import { AppModule } from './app.module';
import { useCaseProviders } from './usecase-providers';
import { authenticationUseCaseProviders } from './usecase-providers/authentication.provider';
import { AuthModule } from './auth.module';
export interface UseCaseConfig {
  name: string;
  instance: any;
}
export const bootstrapDI = async (): Promise<UseCaseConfig[]> => {
  const app = await NestFactory.createApplicationContext(AppModule, {
    abortOnError: false,
    logger: false,
  });
  const useCases = useCaseProviders.map((u) => ({
    name: u.provide,
    instance: app.get(u.provide),
  }));

  return useCases;
};

export const bootstrapAuthDI = async (): Promise<UseCaseConfig[]> => {
  const app = await NestFactory.createApplicationContext(AuthModule, {
    abortOnError: false,
    logger: false,
  });

  const authUseCases = authenticationUseCaseProviders.map((u) => ({
    name: u.provide,
    instance: app.get(u.provide),
  }));

  return authUseCases;
};
