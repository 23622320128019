import {
  CreateSingleMultipleChoiceAnswerUseCase,
  DeleteSingleMultipleChoiceAnswerUseCase,
  SINGLE_MULTIPLE_CHOICE_QUESTION_REPOSITORY,
  ISingleMultipleChoiceQuestionRepository,
  UpdateSingleMultipleChoiceAnswerUseCase,
  UpdateSingleMultipleChoiceCorrectAnswerUseCase,
  CREATE_SINGLE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
  DELETE_SINGLE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
  UPDATE_SINGLE_MULTIPLE_CHOICE_CORRECT_ANSWER_USE_CASE,
  UPDATE_SINGLE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
} from '@module/form';

export const singleMultipleChoiceQuestionUseCaseProviders = [
  {
    provide: CREATE_SINGLE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
    useFactory: (
      questionRepository: ISingleMultipleChoiceQuestionRepository,
    ) => {
      return new CreateSingleMultipleChoiceAnswerUseCase(questionRepository);
    },
    inject: [SINGLE_MULTIPLE_CHOICE_QUESTION_REPOSITORY],
  },
  {
    provide: UPDATE_SINGLE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
    useFactory: (
      questionRepository: ISingleMultipleChoiceQuestionRepository,
    ) => {
      return new UpdateSingleMultipleChoiceAnswerUseCase(questionRepository);
    },
    inject: [SINGLE_MULTIPLE_CHOICE_QUESTION_REPOSITORY],
  },
  {
    provide: DELETE_SINGLE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
    useFactory: (
      questionRepository: ISingleMultipleChoiceQuestionRepository,
    ) => {
      return new DeleteSingleMultipleChoiceAnswerUseCase(questionRepository);
    },
    inject: [SINGLE_MULTIPLE_CHOICE_QUESTION_REPOSITORY],
  },
  {
    provide: UPDATE_SINGLE_MULTIPLE_CHOICE_CORRECT_ANSWER_USE_CASE,
    useFactory: (
      questionRepository: ISingleMultipleChoiceQuestionRepository,
    ) => {
      return new UpdateSingleMultipleChoiceCorrectAnswerUseCase(
        questionRepository,
      );
    },
    inject: [SINGLE_MULTIPLE_CHOICE_QUESTION_REPOSITORY],
  },
];
