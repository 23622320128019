import {
  AssignmentConfigurations,
  AssignmentSummary,
  CreateAssignmentResponse,
} from '@module/assignment';
import {
  EssayQuestion,
  FillInBlankQuestion,
  Form,
  Grade,
  Subject,
  MultipleChoiceQuestion,
  QuestionSummary,
  Section,
  SectionSummary,
  SingleChoiceQuestion,
} from '@module/form';
import { RootReducerState } from 'redux/root.reducer';
import { QuizEditStatus } from './quiz-edit.reducer';

export const questionListSelector = (
  state: RootReducerState,
): (SingleChoiceQuestion | MultipleChoiceQuestion | EssayQuestion | FillInBlankQuestion)[] =>
  state.quizEdit.questions;

export const selectedQuestionIndexSelector = (state: RootReducerState): number =>
  state.quizEdit.selectedQuestionIndex;

export const updateQuestionTypeStatusSelector = (state: RootReducerState): QuizEditStatus =>
  state.quizEdit.updateQuestionTypeStatus;

export const editingQuestionSelector = (state: RootReducerState): string =>
  state.quizEdit.currentlyEditingQuestionId;

export const updateQuestionAnswerStatusSelector = (state: RootReducerState): QuizEditStatus =>
  state.quizEdit.updatingSingleMultipleChoiceAnswerStatus;

export const updateSingleMultipleChoiceCorrectAnswerStatusSelector = (
  state: RootReducerState,
): QuizEditStatus => state.quizEdit.updateSingleMultipleChoiceCorrectAnswerStatus;

export const hasEssayOrFillInBlankQuestionSelector = (state: RootReducerState): boolean =>
  state.quizEdit.hasEssayOrFillInBlankQuestion;

export const questionSummariesSelector = (state: RootReducerState): QuestionSummary[] =>
  state.quizEdit.questionSummaries;

export const createdLocalQuestionStatusSelector = (state: RootReducerState): QuizEditStatus => {
  return state.quizEdit.createLocalQuestionStatus;
};

export const createdQuestionStatusSelector = (state: RootReducerState): QuizEditStatus => {
  return state.quizEdit.createQuestionStatus;
};

export const isLoadingGetQuestionsSelector = (state: RootReducerState): boolean =>
  state.quizEdit.isLoadingGetQuestions;

export const updateQuestionStatusSelector = (state: RootReducerState): QuizEditStatus => {
  return state.quizEdit.updateQuestionStatus;
};

export const createAnswerChoiceQuestionStatusSelector = (
  state: RootReducerState,
): QuizEditStatus => {
  return state.quizEdit.createAnswerStatus;
};

export const deleteQuestionStatusSelector = (state: RootReducerState): QuizEditStatus => {
  return state.quizEdit.deleteQuestionStatus;
};

export const deleteAnswerChoiceQuestionStatusSelector = (
  state: RootReducerState,
): QuizEditStatus => {
  return state.quizEdit.deleteAnswerStatus;
};

export const createAnswerFillInBlankQuestionStatusSelector = (
  state: RootReducerState,
): QuizEditStatus => {
  return state.quizEdit.createFillInBlankAnswerStatus;
};

export const updateFormInformationStatusSelector = (state: RootReducerState): QuizEditStatus => {
  return state.quizEdit.updateFormInformationStatus;
};

export const updateFillInBlankAnswerStatusSelector = (state: RootReducerState): QuizEditStatus => {
  return state.quizEdit.updateFillInBlankAnswerStatus;
};

export const updateFillInBlankCorrectAnswerStatusSelector = (
  state: RootReducerState,
): QuizEditStatus => {
  return state.quizEdit.updateFillInBlankCorrectAnswerStatus;
};

export const deleteFillInBlankAnswerStatusSelector = (state: RootReducerState): QuizEditStatus => {
  return state.quizEdit.deleteFillInBlankAnswerStatus;
};

export const findSectionSummariesSelector = (state: RootReducerState): SectionSummary[] =>
  state.quizEdit.sections;

export const findSectionSummariesStatusSelector = (state: RootReducerState): QuizEditStatus =>
  state.quizEdit.findSectionSummariesStatus;

export const sectionSelectedSelector = (state: RootReducerState): SectionSummary =>
  state.quizEdit.selectedSection;

export const sectionSelectedIndexSelector = (state: RootReducerState): number =>
  state.quizEdit.selectedSectionIndex;

export const findFormByIdSelector = (state: RootReducerState): Form => state.quizEdit.formResponse;

export const findFormByIdStatusSelector = (state: RootReducerState): QuizEditStatus =>
  state.quizEdit.findFormByIdStatus;

export const sectionQuantitySelector = (state: RootReducerState): number =>
  state.quizEdit.sectionQuantity;

export const updateSectionStatusSelector = (state: RootReducerState): QuizEditStatus =>
  state.quizEdit.updateSectionStatus;

export const deleteSectionStatusSelector = (state: RootReducerState): QuizEditStatus =>
  state.quizEdit.deleteSectionStatus;

export const selectedSectionDetailSelector = (state: RootReducerState): Section =>
  state.quizEdit.selectedSectionDetail;
// Assignment
export const assignmentResponseSelector = (state: RootReducerState): AssignmentSummary[] =>
  state.quizEdit.assignmentResponses || [];

export const assignmentConfigsResponseSelector = (
  state: RootReducerState,
): AssignmentConfigurations => state.quizEdit.assignmentConfigsResponse;

export const createAssignmentStatusSelector = (state: RootReducerState): QuizEditStatus =>
  state.quizEdit.createAssignmentStatus;

export const deleteAssignmentStatusSelector = (state: RootReducerState): number =>
  state.quizEdit.deleteAssignmentStatus;

export const createAssignmentResponseSelector = (
  state: RootReducerState,
): CreateAssignmentResponse => state.quizEdit.createAssignmentResponse;

export const effectSelector = (state: RootReducerState): number => state.quizEdit.effect;

export const loadingGetAssignmentsSelector = (state: RootReducerState): boolean =>
  state.quizEdit.isLoadingGetAssignments;

// Grade & Subject
export const gradesAndSubjectsSelector = (state: RootReducerState): [Grade[], Subject[]] =>
  state.quizEdit.gradeAndSubjectResponses;

export const getGradesAndSubjectsStatusSelector = (state: RootReducerState): QuizEditStatus =>
  state.quizEdit.getGradesAndSubjectsStatus;
