import {
  CreateFillInBlankAnswerUseCase,
  DeleteFillInBlankAnswerUseCase,
  DELETE_FILL_IN_BLANK_ANSWER_USE_CASE,
  FILL_IN_BLANK_QUESTION_REPOSITORY,
  IFillInBlankQuestionRepository,
  UpdateFillInBlankAnswerUseCase,
  UpdateFillInBlankCorrectAnswerUseCase,
  UPDATE_FILL_IN_BLANK_CORRECT_ANSWER_USE_CASE,
  CREATE_FILL_IN_BLANK_ANSWER_USE_CASE,
  UPDATE_FILL_IN_BLANK_ANSWER_USE_CASE,
} from '@module/form';

export const fillInBlankQuestionUseCaseProviders = [
  {
    provide: CREATE_FILL_IN_BLANK_ANSWER_USE_CASE,
    useFactory: (questionRepository: IFillInBlankQuestionRepository) => {
      return new CreateFillInBlankAnswerUseCase(questionRepository);
    },
    inject: [FILL_IN_BLANK_QUESTION_REPOSITORY],
  },
  {
    provide: UPDATE_FILL_IN_BLANK_ANSWER_USE_CASE,
    useFactory: (questionRepository: IFillInBlankQuestionRepository) => {
      return new UpdateFillInBlankAnswerUseCase(questionRepository);
    },
    inject: [FILL_IN_BLANK_QUESTION_REPOSITORY],
  },
  {
    provide: DELETE_FILL_IN_BLANK_ANSWER_USE_CASE,
    useFactory: (questionRepository: IFillInBlankQuestionRepository) => {
      return new DeleteFillInBlankAnswerUseCase(questionRepository);
    },
    inject: [FILL_IN_BLANK_QUESTION_REPOSITORY],
  },
  {
    provide: UPDATE_FILL_IN_BLANK_CORRECT_ANSWER_USE_CASE,
    useFactory: (questionRepository: IFillInBlankQuestionRepository) => {
      return new UpdateFillInBlankCorrectAnswerUseCase(questionRepository);
    },
    inject: [FILL_IN_BLANK_QUESTION_REPOSITORY],
  },
];
