import {
  ASSIGNMENT_REPOSITORY,
  IAssignmentRepository,
  GetAssignmentsUseCase,
  GetAssignmentByIdUseCase,
  GetAttendeesUseCase,
  DeleteAssignmentUseCase,
  GET_ASSIGNMENTS_USE_CASE,
  DELETE_ASSIGNMENT_USE_CASE,
  GET_ASSIGNMENT_BY_ID_USE_CASE,
  GET_ATTENDEES_USE_CASE,
  CREATE_PRACTICE_ASSIGNMENT_USE_CASE,
  CreatePracticeAssignmentUseCase,
  GET_ATTENDEE_BY_ID,
  GetAttendeeByIdUseCase,
  IPracticeAssignmentRepository,
  PRACTICE_ASSIGNMENT_REPOSITORY,
} from '@module/assignment';

export const assignmentUseCaseProviders = [
  {
    provide: GET_ASSIGNMENT_BY_ID_USE_CASE,
    useFactory: (assignmentRepository: IAssignmentRepository) => {
      return new GetAssignmentByIdUseCase(assignmentRepository);
    },
    inject: [ASSIGNMENT_REPOSITORY],
  },
  {
    provide: GET_ASSIGNMENTS_USE_CASE,
    useFactory: (assignmentRepository: IAssignmentRepository) => {
      return new GetAssignmentsUseCase(assignmentRepository);
    },
    inject: [ASSIGNMENT_REPOSITORY],
  },
  {
    provide: DELETE_ASSIGNMENT_USE_CASE,
    useFactory: (assignmentRepository: IAssignmentRepository) => {
      return new DeleteAssignmentUseCase(assignmentRepository);
    },
    inject: [ASSIGNMENT_REPOSITORY],
  },
  {
    provide: GET_ATTENDEES_USE_CASE,
    useFactory: (assignmentRepository: IAssignmentRepository) => {
      return new GetAttendeesUseCase(assignmentRepository);
    },
    inject: [ASSIGNMENT_REPOSITORY],
  },
  // Practice assignment
  {
    provide: CREATE_PRACTICE_ASSIGNMENT_USE_CASE,
    useFactory: (practiceAssignmentRepository: IPracticeAssignmentRepository) => {
      return new CreatePracticeAssignmentUseCase(practiceAssignmentRepository);
    },
    inject: [PRACTICE_ASSIGNMENT_REPOSITORY],
  },
  {
    provide: GET_ATTENDEE_BY_ID,
    useFactory: (assignmentRepository: IAssignmentRepository) => {
      return new GetAttendeeByIdUseCase(assignmentRepository);
    },
    inject: [ASSIGNMENT_REPOSITORY],
  },
];
